import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/use-auth.js";

function PublicRoute({ children }) {
  let auth = useAuth();

  if (!auth.user || auth?.user?.tokenExpired) {
    return children;
  } else {
    return <Navigate to="/projects" />;
  }
}

export default PublicRoute;
