import { Fragment, useEffect, useMemo, useState } from "react";
import * as mathjs from "mathjs";
import MosaicPanel from "src/components/common/MosaicPanel";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import TreeVisualizer from "../TreeVisualizer";
import MetricsVisualizer from "../MetricsVisualizer";
import ExpressisonVisualizer from "./ExpressionVusializer";
import {
  DialogMode,
  ExpressionFormat,
  FIRST_ITEM_INDEX,
  SESSION_REQ_INTERVAL,
} from "src/utils/types";
import MetricsHistogram from "../MetricsHistogram";
import { calcSingleTreeMetrics, INITIAL_TILE_LAYOUT, TreeType } from "../utils";
import CreateSessionModal from "src/components/session/CreateSessionModal";
import { ExprParser } from "src/utils/ExpressionParser";
import {
  useGetSortedMultiTreeTestResuls,
  useRunSession,
} from "src/hooks/sessions";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import { getErrorMsg } from "src/utils/Utils";
import QuickTestResults from "./QuickTestResults";

const SingleTreeEvaluation = () => {
  const { notify } = useNotifier();
  const { filteredSolutions, session, configurations } = useMultiTreeSession();
  const expressionsLenght = filteredSolutions ? filteredSolutions.length : 0;

  const [parsedExpTree, setParsedExpTree] = useState(null);
  const [parsingError, setParsingError] = useState(null);
  const [expressionIndex, setExpressionIndex] = useState(FIRST_ITEM_INDEX);

  const [newTestSessionDialogOpen, setNewTestSessionDialogOpen] =
    useState(false);
  const [quickTestLoading, setQuickTestLoading] = useState(false);
  const [quickTestSessionId, setQuickTestSessionId] = useState(null);
  const [quickTestCustomExpressions, setQuickTestCustomExpressions] = useState(
    new Map()
  );
  const [evaluatedTestResults, setEvaluatedTestResults] = useState(new Map());
  const [editedExpression, setEditedExpression] = useState({
    original: "",
    plain: "",
  });

  const [tilesLayout, setTilesLayout] = useState(INITIAL_TILE_LAYOUT);
  const [intervalMs, setIntervalMs] = useState(false);

  const { mutateAsync: runSessionMutation } = useRunSession();
  const { mutateAsync: getSortedTestResults } =
    useGetSortedMultiTreeTestResuls();

  useEffect(() => {
    if (!intervalMs || !quickTestSessionId) return;
    let interval;

    const fetchData = async () => {
      try {
        const data = await getSortedTestResults({
          sessionId: quickTestSessionId,
        });
        if (data?._id) {
          // if not empty response
          const activeSolutionId = activeSolution.id;
          const existingTestResults = evaluatedTestResults;
          const activeSessionTests =
            existingTestResults.get(activeSolutionId) || [];
          const mappedResults = (data.results || []).map((item) => ({
            ...item,
            sessionId: quickTestSessionId,
          }));

          if (activeSessionTests.length > 0) {
            existingTestResults.set(activeSolutionId, [
              ...mappedResults,
              ...activeSessionTests,
            ]);
          } else {
            existingTestResults.set(activeSolutionId, mappedResults);
          }
          console.log("existingTestResults", existingTestResults);
          setQuickTestLoading(false);
          setEvaluatedTestResults(existingTestResults);
          setIntervalMs(null);
          setQuickTestSessionId(null);
          notify(NotificationType.SUCCESS, "Quick test finished.");
        }
      } catch (error) {
        console.error("Error fetching populations:", error);
      }
    };

    // Fetch data immediately when sort, page or interval changes
    fetchData();

    if (intervalMs) {
      interval = setInterval(() => {
        fetchData();
      }, SESSION_REQ_INTERVAL);
    }

    return () => clearInterval(interval);
  }, [intervalMs, quickTestSessionId]);

  const updateExpressionTree = (exp) => {
    setParsingError(null);

    let tempExp = exp;
    const sessionConfID = session.configuration?.id;

    if (!sessionConfID) {
      return;
    }

    const confDetails = configurations.find((c) => c.id === sessionConfID);

    if (
      session.algorithm.exprFormat === ExpressionFormat.PREFIX &&
      confDetails
    ) {
      const exprParser = new ExprParser();
      tempExp = exprParser.prefix2infix(exp);
      tempExp = exprParser.sanitizeGPGomeaModel(tempExp);
    }

    try {
      const parsedExpTree = mathjs.parse(tempExp);
      setParsedExpTree(parsedExpTree);
    } catch (error) {
      setParsingError(error);
    }
  };

  const activeSolution = useMemo(() => {
    if (filteredSolutions && filteredSolutions.length > 0) {
      return filteredSolutions[expressionIndex];
    }

    return null;
  }, [expressionIndex, filteredSolutions]);

  useEffect(() => {
    if (activeSolution?.model) {
      setQuickTestLoading(false);
      const originalExpression = activeSolution.model;
      setEditedExpression({
        original: originalExpression,
        plain: originalExpression,
      });
      updateExpressionTree(originalExpression);
    }
  }, [activeSolution?.id]);

  useEffect(() => {
    if (activeSolution?.id) {
      const hasTestResults = Boolean(
        (evaluatedTestResults.get(activeSolution?.id) || []).length > 0
      );
      if (hasTestResults) {
        handleCreateTestResultWindow();
      } else {
        setTilesLayout(INITIAL_TILE_LAYOUT);
      }
    }
  }, [intervalMs, activeSolution?.id]);

  const metrics = useMemo(() => {
    if (activeSolution) {
      return {
        model: {
          fitness: activeSolution.fitness,
          size: activeSolution.size,
          ...activeSolution.other,
        },
      };
    }

    return null;
  }, [activeSolution]);

  const metricsStats = useMemo(() => {
    if (!filteredSolutions) {
      return null;
    }

    const { averages, maxes, mins } = calcSingleTreeMetrics(filteredSolutions);

    return { model: { averages, maxes, mins } };
  }, [filteredSolutions]);

  const incrementExpressionIndex = () => {
    setExpressionIndex((curIndex) => (curIndex + 1) % expressionsLenght);
  };

  const decrementExpressionIndex = () => {
    setExpressionIndex((curIndex) =>
      curIndex === 0 ? expressionsLenght - 1 : curIndex - 1
    );
  };

  const handleChangeMosaicLayout = (newCurrentNode) => {
    setTilesLayout({
      ...tilesLayout,
      currentNode: newCurrentNode,
    });
  };

  const handleCreateTestResultWindow = () => {
    setTilesLayout({
      ...tilesLayout,
      currentNode: {
        ...tilesLayout.currentNode,
        first: {
          direction: "column",
          first: "expression",
          splitPercentage: 33,
          second: {
            direction: "column",
            first: "quick_test_results",
            second: "metrics",
            splitPercentage: 50
          },
        },
      },
      windowsCount: 4,
    });
  };

  const createQuickTest = (sessionId) => {
    setQuickTestLoading(true);
    const expMap = quickTestCustomExpressions;
    expMap.set(sessionId, editedExpression.plain);
    setQuickTestCustomExpressions(expMap);

    runSessionMutation({ sessionId: sessionId })
      .then(() => {
        setQuickTestSessionId(sessionId);
        setIntervalMs(SESSION_REQ_INTERVAL);
      })
      .catch((error) => {
        setQuickTestLoading(false);
        notify(NotificationType.ERROR, getErrorMsg(error));
      });
  };

  // console.log("activeSolution", activeSolution);
  const activeQuickTestResults = activeSolution?.id
    ? evaluatedTestResults.get(activeSolution.id)
    : [];

  const ELEMENT_MAP = {
    expression: activeSolution && (
      <ExpressisonVisualizer
        editedExpression={editedExpression}
        quickTestLoading={quickTestLoading}
        parsingError={parsingError}
        onChangeEditedExpression={setEditedExpression}
        onUpdateExpressionTree={updateExpressionTree}
        onOpenNewTestDialog={() => setNewTestSessionDialogOpen(true)}
        incrementExpressionIndex={incrementExpressionIndex}
        decrementExpressionIndex={decrementExpressionIndex}
      />
    ),
    metrics: metrics && metricsStats && (
      <MetricsVisualizer
        metrics={metrics}
        metricsStats={metricsStats}
        treeType={TreeType.SINGLE}
      />
    ),
    quick_test_results: <QuickTestResults results={activeQuickTestResults} />,
    tree: parsedExpTree && !parsingError && (
      <TreeVisualizer
        parsedExpTree={parsedExpTree}
        selectedTreeItem={activeSolution}
        treeType={TreeType.SINGLE}
      />
    )
  };

  return (
    <Fragment>
      <MosaicPanel
        tilesLayout={tilesLayout}
        onChangeLayout={handleChangeMosaicLayout}
        mosaicId="evaluation"
        elementMap={ELEMENT_MAP}
      />
      {newTestSessionDialogOpen && (
        <CreateSessionModal
          open={true}
          existingSession={session}
          customExpression={editedExpression.plain}
          mode={DialogMode.QUICK_TEST_SESSION}
          onCreateQuickTest={createQuickTest}
          onClose={() => setNewTestSessionDialogOpen(false)}
        />
      )}
    </Fragment>
  );
};

export default SingleTreeEvaluation;
