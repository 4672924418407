import { useMemo } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  StyledTableCell,
  StyledTableRow,
} from "src/components/common/TableItems";
import { BookmarkSimple } from "phosphor-react";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";

const SingleTreeTable = ({
  bookmarkedItems,
  onChangeSortField,
  onBookmark,
  onRemoveBookmark,
}) => {
  const { filteredSolutions, comparisonSort } = useMultiTreeSession();

  const otherFields = useMemo(() => {
    if ((filteredSolutions || []).length === 0) {
      return [];
    }

    let metrics = new Set();
    filteredSolutions.forEach((item) => {
      if (item.other) {
        Object.keys(item.other).forEach((key) => {
          metrics.add(key);
        });
      }
    });

    return Array.from(metrics);
  }, [filteredSolutions]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <StyledTableRow>
          <StyledTableCell align="left" sortDirection={comparisonSort.order}>
              <TableSortLabel
                active={comparisonSort.key === "generation"}
                direction={comparisonSort.order}
                onClick={() => onChangeSortField("generation")}
              >
                #
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell align="left">MODEL</StyledTableCell>
            <StyledTableCell
              align="left"
              sortDirection={comparisonSort.order}
            >
              <TableSortLabel
                active={comparisonSort.key === "size"}
                direction={comparisonSort.order}
                onClick={() => onChangeSortField("size")}
              >
                SIZE
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell
              align="left"
              sortDirection={comparisonSort.order}
            >
              <TableSortLabel
                active={comparisonSort.key === "fitness"}
                direction={comparisonSort.order}
                onClick={() => onChangeSortField("fitness")}
              >
                FITNESS
              </TableSortLabel>
            </StyledTableCell>
            {otherFields.map((key, index) => (
              <StyledTableCell
                key={key}
                align="left"
                sortDirection={comparisonSort.order}
              >
                <TableSortLabel
                  active={comparisonSort.key === key}
                  direction={comparisonSort.order}
                  onClick={() => onChangeSortField(key)}
                >
                  {key}
                </TableSortLabel>
              </StyledTableCell>
            ))}
            <StyledTableCell align="center"></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {filteredSolutions.map((item) => {
            const isBookmarkedSolution = Boolean(bookmarkedItems.has(item.id));
            return (
              <StyledTableRow key={item.id}>
                <StyledTableCell
                  key={`${item.generation}-${item.individual}`}
                  align="left"
                >
                  {`G${item.generation}:M${item.individual}`}
                </StyledTableCell>
                <CopyToClipboard text={item.model}>
                  <StyledTableCell
                    align="left"
                    title={item.model}
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "400px",
                      maxWidth: "400px",
                      cursor: "copy",
                    }}
                  >
                    {item.model}
                  </StyledTableCell>
                </CopyToClipboard>
                <StyledTableCell align="left">{item.size}</StyledTableCell>
                <StyledTableCell align="left">{item.fitness}</StyledTableCell>
                {otherFields.map((key, index) => {
                  return (
                    <StyledTableCell align="left">
                      {item.other ? item.other[key] || "-" : "-"}
                    </StyledTableCell>
                  );
                })}
                <StyledTableCell align="center">
                  <IconButton
                    sx={{ width: "32px", height: "32px" }}
                    onClick={() => {
                      isBookmarkedSolution
                        ? onRemoveBookmark(item.id)
                        : onBookmark(item.resultId, item.id);
                    }}
                  >
                    <BookmarkSimple
                      weight="fill"
                      size={17}
                      color={`${isBookmarkedSolution ? "#0101ff" : "#b9c3cb"}`}
                    />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SingleTreeTable;
