import {
  Mosaic,
  MosaicWindow,
  MosaicZeroState,
  SplitButton,
  ExpandButton,
  RemoveButton,
  MosaicWithoutDragDropContext,
} from "react-mosaic-component";
import ReplaceButton from "../multitree/ReplaceButton";

const MosaicPanel = ({
  tilesLayout,
  mosaicId,
  toolbarControls = false,
  createNode,
  elementMap,
  replaceTile,
  removeTile,
  onChangeLayout,
}) => {
  return toolbarControls ? (
    <MosaicWithoutDragDropContext
      mosaicId={mosaicId}
      initialValue={tilesLayout.currentNode}
      zeroStateView={<MosaicZeroState createNode={createNode} />}
      onChange={(newCurrentNode) => onChangeLayout(newCurrentNode)}
      renderTile={(id, path) => (
        <MosaicWindow
          path={path}
          title={id}
          toolbarControls={
            tilesLayout.closedWindows.includes("new")
              ? [
                  <ReplaceButton
                    options={tilesLayout.closedWindows}
                    replaceTile={replaceTile}
                    tileId={id}
                    path={path}
                    key="replace"
                  />,
                  <SplitButton key="split" />,
                  <ExpandButton key="expand" />,
                  tilesLayout.windowsCount > 1 ? (
                    <RemoveButton key="remove" onClick={() => removeTile(id)} />
                  ) : (
                    <></>
                  ),
                ]
              : [
                  <ReplaceButton
                    options={tilesLayout.closedWindows}
                    replaceTile={replaceTile}
                    tileId={id}
                    path={path}
                    key="replace"
                  />,
                  <ExpandButton key="expand" />,
                  tilesLayout.windowsCount > 1 ? (
                    <RemoveButton key="remove" onClick={() => removeTile(id)} />
                  ) : (
                    <></>
                  ),
                ]
          }
          createNode={createNode}
        >
          {elementMap[id]}
        </MosaicWindow>
      )}
    />
  ) : (
    <Mosaic
      mosaicId={mosaicId}
      initialValue={tilesLayout.currentNode}
      zeroStateView={<MosaicZeroState createNode={createNode} />}
      onChange={(newCurrentNode) => {
        onChangeLayout(newCurrentNode);
      }}
      renderTile={(id, path) => (
        <MosaicWindow path={path} title={id} createNode={createNode}>
          {elementMap[id]}
        </MosaicWindow>
      )}
    />
  );
};

export default MosaicPanel;
