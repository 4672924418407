import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Checkbox, Grid, Card, Button, Stack } from "@mui/material";
import { StyledTableCell } from "../components/common/TableItems";
import WhatifAnalyseDialog from "../components/whatif/WhatifAnalyseDialog";
import Alert from "../components/Alert";
import { useGetPopulationsofSession, useGetSessions } from "../hooks/sessions";
import { useGetDatasetData } from "../hooks/datasets";
import { SessionTypes } from "src/utils/types";
import ProjectLayout from "src/layouts/ProjectLayout";
import SessionSelector from "src/components/common/SessionSelector";
import InstanceSelector from "src/components/common/InstanceSelector";
import ExpressionSelector from "src/components/common/ExpressionSelector";

const Whatif = () => {
  let params = useParams();
  const [allPopulations, setAllPopulations] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedExpression, setSelectedExpression] = useState(null);
  const [selectedInstance, setSelectedInstance] = useState([]);
  const [selectedInstanceIndex, setSelectedInstanceIndex] = useState(null);
  const [whatifDialogOpen, setWhatifDialogOpen] = useState(false);

  const { data: sessions, isLoading: sessionLoading } = useGetSessions(
    params.id
  );
  const { data: allPopulationsIndividuals, isFetching: populationsFetching } =
    useGetPopulationsofSession({ sessionId: selectedSession?.value });

  const { data: datasetData, isFetching: dataFetching } = useGetDatasetData({
    projectId: params.id,
    datasetId: selectedSession?.dataset?.id,
    delimiter: null,
    fileName: selectedSession?.dataset?.name,
  });

  const targetLabel = selectedSession
    ? sessions.data.find((item) => item.id === selectedSession.value).target
    : null;

  useEffect(() => {
    setSelectedExpression(null);
    setSelectedInstance([]);
    setSelectedInstanceIndex(null);
  }, [selectedSession?.value]);

  useEffect(() => {
    if (!allPopulationsIndividuals) return;
    let solutionsList = [];
    for (const population in allPopulationsIndividuals) {
      solutionsList = solutionsList.concat(
        allPopulationsIndividuals[population]
      );
    }
    setAllPopulations(solutionsList);
  }, [allPopulationsIndividuals]);

  const handleSelectExpression = (populationItem) => {
    if (selectedExpression?.id === populationItem.id) {
      setSelectedExpression(null);
      return;
    }

    setSelectedExpression({
      id: populationItem.id,
      model: populationItem.model,
    });
  };

  const handleSelectInstance = (rowData, index) => {
    if (selectedInstanceIndex === index) {
      setSelectedInstanceIndex(null);
      return;
    }

    setSelectedInstanceIndex(index);
    setSelectedInstance(rowData);
  };

  const renderExpressionTableRow = (solution) => {
    return (
      <>
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={
              selectedExpression
                ? solution.id === selectedExpression.id
                  ? true
                  : false
                : false
            }
            onChange={() => handleSelectExpression(solution)}
          />
        </StyledTableCell>
        <StyledTableCell align="left">{solution.model}</StyledTableCell>
        <StyledTableCell align="left">{solution.fitness}</StyledTableCell>
      </>
    );
  };

  return (
    <ProjectLayout tab={"whatif"}>
      <div className="project-tab" id="whatif-section">
        {sessionLoading ? (
          <div className="table-loader" />
        ) : (
          <>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                <SessionSelector
                  selectedSession={selectedSession}
                  sessions={(sessions
                    ? sessions.data.length > 0
                      ? sessions.data
                      : []
                    : []
                  ).filter(
                    (item) =>
                      item.properties.commandType === SessionTypes.TRAIN &&
                      !item.algorithm.multiTree
                  )}
                  loading={sessionLoading}
                  onChangeSession={setSelectedSession}
                />
              </Grid>
              <Grid item xs={12}>
                {!sessionLoading && sessions?.data.length === 0 && (
                  <Alert
                    type="info"
                    message="No session found. Please create a session first."
                  />
                )}
                {!sessionLoading &&
                  sessions?.data.length > 0 &&
                  !selectedSession && (
                    <Alert
                      type="info"
                      message="Select a session to see details."
                    />
                  )}
              </Grid>
            </Grid>
            {selectedSession && (
              <>
                <InstanceSelector
                  datasetData={datasetData}
                  loading={dataFetching}
                  selectedInstanceIndex={selectedInstanceIndex}
                  onSelectInstance={handleSelectInstance}
                />
                <Stack mt={"2rem"} pb={"5rem"}>
                  <ExpressionSelector
                    headers={[
                      { title: "#" },
                      { title: "Expression" },
                      { title: "Fitness" },
                    ]}
                    allPopulations={allPopulations}
                    allPopulationsIndividuals={allPopulationsIndividuals}
                    loading={populationsFetching}
                    renderExpressionTableRow={renderExpressionTableRow}
                  />
                </Stack>
              </>
            )}
          </>
        )}

        {selectedInstanceIndex !== null && selectedExpression && (
          <Card
            sx={{
              width: "100%",
              position: "fixed",
              bottom: "0",
              right: "0",
              left: "0",
              padding: ".75rem 1rem",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              zIndex: whatifDialogOpen ? "-1" : "9999",
            }}
          >
            <Button
              variant="contained"
              sx={{ height: "auto", padding: ".375rem 1rem" }}
              onClick={() => setWhatifDialogOpen(true)}
            >
              Submit
            </Button>
          </Card>
        )}

        {whatifDialogOpen && selectedSession && (
          <WhatifAnalyseDialog
            open={whatifDialogOpen}
            sessionId={selectedSession?.value}
            instance={selectedInstance}
            dataHeaders={datasetData.data.header}
            expression={selectedExpression.model}
            expressions={new Set(allPopulations.map((e) => e.model))}
            targetLabel={targetLabel}
            allPopulations={allPopulations}
            onClose={() => setWhatifDialogOpen(false)}
          />
        )}
      </div>
    </ProjectLayout>
  );
};

export default Whatif;
