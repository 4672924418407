import { useEffect, useState } from "react";
import { ArrowCounterClockwise, SlidersHorizontal } from "phosphor-react";
import {
  Alert,
  Button,
  Chip,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import {
  defaultPopulationModelKeys,
  FilterOperators,
  MULTI_TREE_FIRST_PAGE,
  MultiTreeFilterType,
  TreeMetricFilterType,
} from "src/utils/types";

function ComparisonFilters() {
  const {
    session,
    comparisonFilterFields,
    comparisonFilters,
    setComparisonFilters,
    setComparisonPagination,
  } = useMultiTreeSession();
  const [localAppliedFilters, setLocalAppliedFilters] = useState([]);
  const [treeMetricFilterTypes, setTreeMetricFilterTypes] = useState(new Map());

  const isMultiTreeSession = Boolean(session?.algorithm?.multiTree);

  useEffect(() => {
    setLocalAppliedFilters(comparisonFilters);
  }, [JSON.stringify(comparisonFilters)]);

  const addFilter = (key, operator, value, type) => {
    let existingFilters = [...localAppliedFilters];
    const isModel = type == MultiTreeFilterType.MODEL;
    const modelKey =
      isModel && isMultiTreeSession
        ? defaultPopulationModelKeys.includes(key)
          ? key
          : `other.${key}`
        : key;

    let filter = existingFilters.find(
      (item) =>
        item.key === modelKey && item.op === operator && item.metric == type
    );

    if (filter) {
      existingFilters = value
        ? existingFilters.map((item) => {
            if (item.key === modelKey && item.op === operator) {
              return { ...item, value: Number(value) };
            } else {
              return item;
            }
          })
        : existingFilters.filter((item) => {
            const sameKeys = item.key === modelKey;
            const sameOp = item.op === operator;
            const sameItem = sameKeys && sameOp;
            return !sameItem;
          });
    } else {
      let newFilter = {
        key: modelKey,
        op: operator,
        value: Number(value),
        metric: type,
      };
      existingFilters.push(newFilter);
    }

    setLocalAppliedFilters(existingFilters);
  };

  const onChangeTreeMetricType = (item, type) => {
    let existingFilters = treeMetricFilterTypes;
    existingFilters.set(item, type);
    setTreeMetricFilterTypes(new Map(existingFilters));
  };

  const applyFilters = () => {
    let filters = [...localAppliedFilters].map((item) => {
      if (item.metric == MultiTreeFilterType.TREE) {
        return {
          ...item,
          treeMetricType:
            treeMetricFilterTypes.get(item.key) ||
            TreeMetricFilterType.UNIVERSAL,
        };
      } else {
        return item;
      }
    });
    setComparisonPagination((prev) => ({
      ...prev,
      page: MULTI_TREE_FIRST_PAGE,
    }));
    setComparisonFilters(filters);
  };

  const resetFilters = () => {
    setComparisonFilters([]);
  };

  return (
    <div style={{ paddingBottom: "5rem" }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={".5rem"}
      >
        <h5 style={{ margin: 0 }}>Filter Data</h5>
        <Button
          size="small"
          startIcon={<ArrowCounterClockwise />}
          onClick={resetFilters}
        >
          Reset
        </Button>
      </Stack>
      <Alert severity="info" sx={{ margin: ".25rem 0 1rem 0" }}>
        Both min max filters are inclusive
      </Alert>
      <Stack gap={"1.25rem"} mb={"1.5rem"} mt={"1.5rem"}>
        {comparisonFilterFields.map((item) => {
          const minVal = localAppliedFilters.find(
            (lf) => lf.key == item.key && lf.op == FilterOperators.MIN
          )?.value;

          const maxVal = localAppliedFilters.find(
            (lf) => lf.key == item.key && lf.op == FilterOperators.MAX
          )?.value;

          return (
            <Stack gap={".25rem"} key={item.key}>
              <Stack
                direction={"row"}
                gap={"1rem"}
                justifyContent={"space-between"}
              >
                <Typography variant="body2" fontWeight={600}>
                  {item.key}
                </Typography>
                {isMultiTreeSession &&
                  item.metricType === MultiTreeFilterType.TREE && (
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      gap={".5rem"}
                    >
                      <IconButton
                        sx={{ padding: "0rem", height: "fit-content" }}
                        onClick={() =>
                          onChangeTreeMetricType(
                            item.key,
                            TreeMetricFilterType.UNIVERSAL
                          )
                        }
                      >
                        <Chip
                          label={TreeMetricFilterType.UNIVERSAL}
                          variant={
                            !treeMetricFilterTypes.get(item.key) ||
                            treeMetricFilterTypes.get(item.key) ==
                              TreeMetricFilterType.UNIVERSAL
                              ? "filled"
                              : "outlined"
                          }
                          size="small"
                          sx={{ fontSize: ".675rem" }}
                        />
                      </IconButton>
                      <IconButton
                        sx={{ padding: "0rem", height: "fit-content" }}
                        onClick={() =>
                          onChangeTreeMetricType(
                            item.key,
                            TreeMetricFilterType.EXISTENSIAL
                          )
                        }
                      >
                        <Chip
                          label={TreeMetricFilterType.EXISTENSIAL}
                          variant={
                            treeMetricFilterTypes.get(item.key) ==
                            TreeMetricFilterType.EXISTENSIAL
                              ? "filled"
                              : "outlined"
                          }
                          size="small"
                          sx={{ fontSize: ".675rem" }}
                        />
                      </IconButton>
                    </Stack>
                  )}
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"flex-start"}
                gap={"1rem"}
                mt={".5rem"}
              >
                <Stack flex={1}>
                  <TextField
                    key={`tree-${item.key}-min`}
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="min"
                    type="number"
                    value={typeof minVal == "number" ? minVal : ""}
                    inputProps={{ min: item.min, max: item.max }}
                    error={typeof minVal == "number" && item.min > minVal}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      addFilter(
                        item.key,
                        FilterOperators.MIN,
                        e.target.value,
                        item.metricType
                      )
                    }
                  />
                  <Typography variant="caption">{`min: ${Number(item.min).toFixed(6)}`}</Typography>
                </Stack>
                <Stack flex={1}>
                  <TextField
                    key={`tree-${item.key}-max`}
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="max"
                    type="number"
                    value={typeof maxVal == "number" ? maxVal : ""}
                    inputProps={{ min: item.min, max: item.max }}
                    error={typeof maxVal == "number" && item.max < maxVal}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      addFilter(
                        item.key,
                        FilterOperators.MAX,
                        e.target.value,
                        item.metricType
                      )
                    }
                  />
                  <Typography variant="caption">{`max: ${Number(item.max).toFixed(6)}`}</Typography>
                </Stack>
              </Stack>
            </Stack>
          );
        })}
      </Stack>

      <Stack gap={".5rem"}>
        <button
          id="apply-filters-button"
          className="icon-button"
          type="button"
          disabled={comparisonFilterFields.length == 0}
          onClick={applyFilters}
        >
          <SlidersHorizontal size={18} color="#ffffff" weight="bold" />
          <span>Apply</span>
        </button>
      </Stack>
    </div>
  );
}

export default ComparisonFilters;
