import { useMemo, Fragment } from "react";
import { useParams } from "react-router-dom";
import { MentionsInput, Mention } from "react-mentions";
import {
  ArrowFatLineLeft,
  ArrowFatLineRight,
  BookmarkSimple,
} from "phosphor-react";
import style from "src/components/common/mentions.module.css";
import { useGetAlgorithms } from "src/hooks/algorithms";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useCreateAlgorithmBookmark } from "src/hooks/sessions";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";

const ExpressisonVisualizer = ({
  editedExpression,
  quickTestLoading,
  parsingError,
  onChangeEditedExpression,
  onUpdateExpressionTree,
  onOpenNewTestDialog,
  incrementExpressionIndex,
  decrementExpressionIndex,
}) => {
  let params = useParams();
  const { notify } = useNotifier();
  const { session } = useMultiTreeSession();

  const { data: algorithmsData } = useGetAlgorithms();
  const { mutateAsync: createAlgortihmBookmark } = useCreateAlgorithmBookmark();

  const editorVariablesAndOperators = useMemo(() => {
    if (!session || !algorithmsData) {
      return null;
    }

    const sessionType = session.properties.commandType;
    const algoId = session.algorithm.id;
    const selectedAlgorithm = algorithmsData?.data.find(
      (item) => item.id === algoId
    );
    const algoProperties = selectedAlgorithm?.commands.find(
      (c) => c.commandType === sessionType
    )?.schema.properties;

    return {
      headers: session.headers.map((variable) => ({
        id: variable,
        display: variable,
      })),
      functions: algoProperties
        ? algoProperties.functions_set?.items.enum.map((item) => ({
            id: item,
            display: item,
          }))
        : [],
      terminals: algoProperties
        ? algoProperties.terminals_set?.items.enum.map((item) => ({
            id: item,
            display: item,
          }))
        : [],
    };
  }, [session, algorithmsData]);

  async function bookmark() {
    const customExpression = editedExpression.plain;
    try {
      await createAlgortihmBookmark({
        projectId: params.id,
        algorithmId: session.algorithm.id,
        exprs: [customExpression],
      });

      notify(NotificationType.SUCCESS, `${customExpression} bookmarked.`);
    } catch (error) {
      notify(
        NotificationType.ERROR,
        `${customExpression} could not bookmarked! Please try later.`
      );
    }
  }

  return (
    <>
      <div id="expression-container">
        <span id="bookmark" onClick={() => bookmark()}>
          <BookmarkSimple weight="fill" size={20} />
        </span>
        <section
          id="tree-expression-form"
          className={parsingError ? "with-error" : ""}
        >
          <MentionsInput
            classNames={style}
            value={editedExpression.original}
            placeholder="Mention variables using '$' and operatores using '@'"
            onChange={(e, newValue, newPlainTextValue, mentions) => {
              onChangeEditedExpression({
                original: e.target.value,
                plain: newPlainTextValue,
              });
            }}
          >
            <Mention
              className={style.mentions__mention}
              trigger="$"
              data={editorVariablesAndOperators?.headers || []}
            />

            <Mention
              className={style.mentions__mention}
              trigger="@"
              data={[
                ...(editorVariablesAndOperators?.functions || []),
                ...(editorVariablesAndOperators?.terminals || []),
              ]}
            />
          </MentionsInput>
          <Box
            sx={{
              position: "absolute",
              left: "0",
              bottom: "0",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
              padding: ".5rem",
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
            >
              <Typography id={parsingError ? "expression-error" : ""}>
                {parsingError ? (
                  <Fragment>
                    <b>Parsing Error</b> <br /> {parsingError.message}
                  </Fragment>
                ) : (
                  ""
                )}
              </Typography>
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"flex-end"}
                gap={"1.5rem"}
              >
                <Stack direction={"row"} alignItems={"center"} gap={".125rem"}>
                  <IconButton onClick={decrementExpressionIndex}>
                    <ArrowFatLineLeft />
                  </IconButton>
                  <IconButton onClick={incrementExpressionIndex}>
                    <ArrowFatLineRight />
                  </IconButton>
                </Stack>
                <Stack gap={".5rem"}>
                  <Button
                    variant="outlined"
                    onClick={() =>  onOpenNewTestDialog(true)}
                    sx={{
                      height: "37px",
                    }}
                    startIcon={quickTestLoading ? <CircularProgress
                      size={16}
                      sx={{ color: t =>  t.palette.grey[500] }}
                    /> : undefined}
                    disabled={quickTestLoading}
                  >
                    QUICK TEST
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      onUpdateExpressionTree(editedExpression.plain)
                    }
                    sx={{
                      height: "37px",
                    }}
                  >
                    Update Tree
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Alert severity="info">
              <Typography variant="caption">
                1- This will be saved to algorithm bookmarks. Can be seen at
                bookmarkeds tab
              </Typography>
              <br />
              <Typography variant="caption">
                2- Type <b>$</b> for headers, <b>@</b> for functions and
                terminals. Give 1 character empty space before <b>$ or @</b> to
                see options,
              </Typography>
            </Alert>
          </Box>
        </section>
      </div>
    </>
  );
};

export default ExpressisonVisualizer;
