import { Fragment, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  ContinuousDistanceType,
  ExampleCodiceConfigFileContent,
  LossType,
  ModelType,
  resultsCountsValues,
} from "./utils";
import SelectOutputRange from "./SelectOutputRange";
import { Download, UploadSimple } from "phosphor-react";
import Dropzone from "react-dropzone";
import useNotifier, { NotificationType } from "src/hooks/use-notify";

const CodiceFormSection = ({
  modelType,
  resultsCount,
  outputRange,
  maxIter,
  classificationOutput,
  continuousDistanceType,
  lossType,
  distance,
  sparsity,
  coherence,
  onChangeCodiceConfigs,
  onChangeMaxIter,
  onChangeClassificationOutput,
  onChangeOutputRange,
  onChangeResultCount,
  onChangeModelType,
  onChangeDistanceType,
  onChangeLossType,
  onChangeDistance,
  onChangeSparsity,
  onChangeCoherence,
}) => {
  const { notify } = useNotifier();
  const [configFile, setConfigFile] = useState(null);
  const [configFileValidationErrors, setConfigFileValidationErrors] = useState(
    []
  );

  const onDropConfigFile = (acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      fileRejections.forEach((item) => {
        item.errors.forEach((err) => {
          notify(NotificationType.ERROR, `${err.message}`);
        });
      });
      return;
    }

    if (acceptedFiles.length > 0) {
      const uploadedConfigFile = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = () => {
        try {
          const json = JSON.parse(reader.result);
          console.log("json", json);
          onChangeCodiceConfigs(json);
        } catch (error) {
          setConfigFileValidationErrors(["Invalid JSON file"]);
        }
      };

      reader.onerror = () => {
        setConfigFileValidationErrors(["Error reading file"]);
      };

      reader.readAsText(uploadedConfigFile);
      setConfigFile(uploadedConfigFile);
    }
  };

  const downloadExampleConfigFile = () => {
    const url = window.URL.createObjectURL(
      new Blob([ExampleCodiceConfigFileContent], { type: "text/json" })
    );
    const a = document.createElement("a");
    a.href = url;
    a.download = `trustai_example_codice_config.json`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Fragment>
      <Grid item xs={12} sm={4}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            mb: ".3rem",
          }}
        >
          Model Type
        </Typography>
        <Select
          id="model-select"
          fullWidth
          value={modelType}
          onChange={(e) => onChangeModelType(e.target.value)}
        >
          {Object.entries(ModelType).map(([key, val]) => (
            <MenuItem key={key} value={val}>
              {val}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            mb: ".3rem",
          }}
        >
          Results Count
        </Typography>
        <Select
          id="resultcount-select"
          fullWidth
          value={resultsCount}
          onChange={(e) => onChangeResultCount(e.target.value)}
        >
          {resultsCountsValues.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            mb: ".3rem",
          }}
        >
          Max Iteration
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          type="number"
          value={maxIter}
          onChange={(e) => onChangeMaxIter(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        {modelType == ModelType.REGRESSION ? (
          <SelectOutputRange
            title="Output Range"
            desc={
              "(suggested min and max value are %10 below and above the data)"
            }
            modelType={ModelType.REGRESSION}
            value={outputRange}
            onChange={onChangeOutputRange}
          />
        ) : (
          <SelectOutputRange
            title="Desired Class"
            modelType={ModelType.CLASSIFICATION}
            value={classificationOutput}
            onChange={onChangeClassificationOutput}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            mb: ".3rem",
          }}
        >
          Continuous Distance Type
        </Typography>
        <Select
          id="distance-select"
          fullWidth
          value={continuousDistanceType}
          onChange={(e) => onChangeDistanceType(e.target.value)}
        >
          {Object.entries(ContinuousDistanceType).map(([key, val]) => (
            <MenuItem key={key} value={val}>
              {val}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            mb: ".3rem",
          }}
        >
          Loss Type
        </Typography>
        <Select
          id="distance-select"
          fullWidth
          value={lossType}
          onChange={(e) => onChangeLossType(e.target.value)}
        >
          {Object.entries(
            modelType == ModelType.REGRESSION
              ? LossType.Regression
              : LossType.Classification
          ).map(([key, val]) => (
            <MenuItem key={key} value={val}>
              {val}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Stack gap={".75rem"}>
          <Stack direction={"row"} alignItems={"center"} gap={"1.5rem"}>
            <Stack direction={"row"} alignItems={"center"} width={"8rem"}>
              <Switch defaultChecked disabled sx={{ cursor: "not-allowed" }} />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Distance
              </Typography>
            </Stack>
            <TextField
              size="small"
              fullWidth
              sx={{ maxWidth: "10rem" }}
              variant="outlined"
              type="number"
              label='Weight'
              value={distance.value}
              onChange={(e) =>
                onChangeDistance({ ...distance, value: e.target.value })
              }
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={"1.5rem"}>
            <Stack direction={"row"} alignItems={"center"} width={"8rem"}>
              <Switch
                checked={sparsity.status}
                onChange={(e) =>
                  onChangeSparsity({ ...sparsity, status: e.target.checked })
                }
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Sparsity
              </Typography>
            </Stack>
            <TextField
              size="small"
              fullWidth
              disabled={sparsity.status == false}
              sx={{ maxWidth: "10rem" }}
              variant="outlined"
              type="number"
              label='Weight'
              value={sparsity.value}
              onChange={(e) =>
                onChangeSparsity({ ...sparsity, value: e.target.value })
              }
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={"1.5rem"}>
            <Stack direction={"row"} alignItems={"center"} width={"8rem"}>
              <Switch
                checked={coherence.status}
                onChange={(e) =>
                  onChangeCoherence({ ...coherence, status: e.target.checked })
                }
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Coherence
              </Typography>
            </Stack>
            <TextField
              size="small"
              disabled={coherence.status == false}
              fullWidth
              sx={{ maxWidth: "10rem" }}
              variant="outlined"
              type="number"
              label='Weight'
              value={coherence.value}
              onChange={(e) =>
                onChangeCoherence({ ...coherence, value: e.target.value })
              }
            />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              mb: ".3rem",
            }}
          >
            Configuration File
          </Typography>
          <Button
            variant="text"
            size="small"
            sx={{
              textTransform: "none",
              fontSize: ".875rem",
              padding: "0.3rem .75rem",
              height: "fit-content",
            }}
            startIcon={<Download />}
            onClick={downloadExampleConfigFile}
          >
            Download Example File
          </Button>
        </Box>
        <Dropzone onDrop={onDropConfigFile} accept=".json">
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                {...getRootProps({
                  className: configFile ? "dropzone with-file" : "dropzone",
                })}
              >
                <input {...getInputProps()} />
                {configFile ? (
                  <p id="file-name">{configFile.name}</p>
                ) : (
                  <p>
                    Drag and drop <b>configuration.json file</b> here, or click
                    to select file
                  </p>
                )}
                <UploadSimple
                  style={{ marginTop: ".5rem" }}
                  size={18}
                  color="#00e088"
                  weight="bold"
                />
              </div>
            </section>
          )}
        </Dropzone>
        <Box
          sx={{
            width: "100%",
            mt: "1.5rem",
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
          }}
        >
          {configFileValidationErrors.map((err) => (
            <Alert severity="error">{err}</Alert>
          ))}
        </Box>
      </Grid>
    </Fragment>
  );
};

export default CodiceFormSection;
