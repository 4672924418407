import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Trash, Plus, Copy } from "phosphor-react";
import { Alert as MuiAlert } from "@mui/material";
import { useGetAlgorithms } from "../hooks/algorithms";
import { useGetConfigs } from "../hooks/configurations";
import CloneConfigDialog from "src/components/config/CloneConfigDialog";
import ConfigDetails from "src/components/config/ConfigDetails";
import CreateConfigDialog from "src/components/config/CreateConfigDialog";
import Alert from "src/components/Alert";
import DeleteConfigDialog from "src/components/config/DeleteConfigDialog";
import ProjectLayout from "src/layouts/ProjectLayout";

export default function ConfigPage() {
  const params = useParams();
  const { data: configsData, isLoading } = useGetConfigs(params.id);
  const { data: algorithmsData } = useGetAlgorithms();

  const [algorithmList, setAlgorithms] = useState([]);
  const [selectedAlgorithm, setSelectedAlgorithm] = useState();
  const [selectedConfig, setSelectedConfig] = useState();
  const [configs, setConfigs] = useState([]);
  const [search, setSearch] = useState("");

  const [newConfigDialogVisible, setNewConfigDialogVisible] = useState(false);
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    if (configsData?.data) {
      setConfigs(configsData.data);

      const algoritms = algorithmsData?.data;
      if (selectedConfig && (algoritms || []).length > 0) {
        const algorithm = algoritms
          .find(
            (item) =>
              item.id ===
              (typeof selectedConfig.algorithm == "string"
                ? selectedConfig.algorithm
                : selectedConfig.algorithm?.id)
          )
          ?.commands.find(
            (item) => item.commandType === selectedConfig?.commandType
          );
        setSelectedAlgorithm(algorithm);
      }
    }
  }, [configsData, selectedConfig]);

  useEffect(() => {
    if (algorithmsData?.data) {
      const projectAlgorithms = algorithmsData.data.map((algorithm) => {
        return {
          id: algorithm.id,
          label: algorithm.name,
          type: algorithm.type,
          commands: algorithm.commands.map((item) => {
            return {
              id: item.commandType,
              label: item.commandType,
            };
          }),
        };
      });

      setAlgorithms(projectAlgorithms);
    }
  }, [algorithmsData]);

  const handleAddNewConfig = () => {
    setNewConfigDialogVisible(true);
  };

  const handleCopyConfigConfirmation = (config) => {
    setSelectedConfig(config);
    setCloneDialogOpen(true);
  };

  const handleDeleteDialogOpen = (config) => {
    setSelectedConfig(config);
    setDeleteDialogOpen(true);
  };

  const handleChangeConfig = (config) => {
    setSelectedConfig(config);
  };

  const updateSearch = (newSearch) => {
    setSearch(newSearch);
    if (configsData?.data) {
      setConfigs(
        configsData.data.filter((dataset) =>
          dataset.name.toLowerCase().includes(newSearch.toLowerCase())
        )
      );
    }
  };

  return (
    <ProjectLayout tab={"configs"}>
      <div className="project-tab">
        <section id="configs-section">
          <div id="configs-left">
            <div id="config-upload-container">
              <input
                type="text"
                id="config-search"
                placeholder="Search a config"
                value={search}
                onChange={(e) => updateSearch(e.target.value)}
              ></input>
              <button
                className="icon-button submit-button"
                type="button"
                onClick={handleAddNewConfig}
              >
                <Plus size={18} color="#ffffff" weight="bold" />
                <span>Config</span>
              </button>
            </div>
            {configs.length > 0 && (
              <div id="configs-table">
                <div className="table-top">
                  <span className="name">NAME</span>
                  <span className="download"></span>
                  <span className="trash"></span>
                </div>
                <div className="table-body">
                  {configs.map((config) => {
                    const isSelected =
                      selectedConfig && config.id === selectedConfig.id;
                    return (
                      <li
                        key={config.id}
                        className={`table-entry${isSelected ? " selected" : ""}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleChangeConfig(config)}
                      >
                        <div className="info">
                          <span>{config.name}</span>
                          <span className="sub-info">
                            {`${config.algorithm?.name} ${
                              config.commandType
                                ? " - " + config.commandType
                                : ""
                            }`}
                          </span>
                          <span className="config-desc">
                            {config.description}
                          </span>
                        </div>
                        {isSelected && (
                          <>
                            <span
                              className="download"
                              onClick={() =>
                                handleCopyConfigConfirmation(config)
                              }
                            >
                              <Copy size={20} color="#ad1b00" />
                            </span>
                            <span
                              className="trash"
                              onClick={() => handleDeleteDialogOpen(config)}
                            >
                              <Trash size={20} color="#ad1b00" />
                            </span>
                          </>
                        )}
                      </li>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div id="configs-right">
            {configs.length === 0 && !isLoading && (
              <Alert type="info" message="No config found." />
            )}
            {!selectedConfig && configs.length > 0 && (
              <MuiAlert severity="info">Select config to see details</MuiAlert>
            )}
            {selectedConfig && selectedAlgorithm && (
              <ConfigDetails
                config={selectedConfig}
                algorithm={selectedAlgorithm}
              />
            )}
          </div>
        </section>

        {newConfigDialogVisible && (
          <CreateConfigDialog
            open={true}
            algorithms={algorithmList}
            onSelect={setSelectedConfig}
            onClose={() => setNewConfigDialogVisible(false)}
          />
        )}

        {cloneDialogOpen && selectedConfig && (
          <CloneConfigDialog
            open={true}
            conf={selectedConfig}
            onClose={() => setCloneDialogOpen(false)}
          />
        )}

        {deleteDialogOpen && selectedConfig && (
          <DeleteConfigDialog
            open={true}
            conf={selectedConfig}
            onSelect={setSelectedConfig}
            onClose={() => setDeleteDialogOpen(false)}
          />
        )}
      </div>
    </ProjectLayout>
  );
}
