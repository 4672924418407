import { Box, Grid, Skeleton, TableSortLabel, Typography } from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import {
  StyledTableCell,
  StyledTableRow,
  VirtualizedTableComponents,
} from "./TableItems";
import Alert from "../Alert";
import { SortDirection } from "src/utils/types";

const ExpressionSelector = ({
  loading,
  headers = [],
  sortField,
  sortDirection,
  allPopulations,
  allPopulationsIndividuals,
  renderExpressionTableRow,
  onChangeSort,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography className="form-item-label">Select expression</Typography>
      {loading ? (
        <>
          {new Array(5).fill().map(() => (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={30}
              style={{ marginBottom: ".25rem" }}
            />
          ))}
        </>
      ) : allPopulationsIndividuals.length === 0 ? (
        <Grid>
          <Alert
            type="info"
            message="No expression found. Please create or run a session."
          />
        </Grid>
      ) : (
        <TableVirtuoso
          style={{ height: "400px" }}
          data={allPopulations}
          components={VirtualizedTableComponents}
          fixedHeaderContent={() => (
            <StyledTableRow>
              {headers.map((item) => {
                if (item.sortable) {
                  return (
                    <StyledTableCell
                      align="left"
                      key={item.key}
                      sortDirection={
                        sortField === item.key ? sortDirection : false
                      }
                    >
                      <TableSortLabel
                        active={sortField === item.key}
                        direction={
                          sortField === item.key
                            ? sortDirection
                            : SortDirection.ASC
                        }
                        onClick={
                          onChangeSort ? () => onChangeSort(item.key) : () => {}
                        }
                      >
                        {item.title}
                      </TableSortLabel>
                    </StyledTableCell>
                  );
                } else {
                  return (
                    <StyledTableCell align="left" key={item.title}>
                      {item.title}
                    </StyledTableCell>
                  );
                }
              })}
            </StyledTableRow>
          )}
          itemContent={(index, solution) => renderExpressionTableRow(solution)}
        />
      )}
    </Box>
  );
};

export default ExpressionSelector;
