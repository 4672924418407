import { useNavigate } from "react-router-dom";
import { CaretRight } from "phosphor-react";
import { Skeleton } from "@mui/material";

function Breadcrumbs({ ancestors }) {
  let navigate = useNavigate();

  const current = ancestors[ancestors.length - 1].name;

  return (
    <div id="breadcrumbs">
      {ancestors.map(({ name, url }, index) => {
        if(!name){
          return <Skeleton variant="rectangular" width={70} height={28} />
        } 
        if(index == ancestors.length -1){
          return <h4 key={'current'} id="current">{current}</h4>
        }
        return (
          <span key={name}>
            <span onClick={() => navigate(url)}>{name}</span>
            <CaretRight size={14} weight="bold" />
          </span>
        );
      })}
    </div>
  );
}

export default Breadcrumbs;
