import { Box, Checkbox, Grid, Skeleton, Typography } from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import {
  StyledTableCell,
  StyledTableRow,
  VirtualizedTableComponents,
} from "./TableItems";
import Alert from "../Alert";

const InstanceSelector = ({
  title = "Select an instance",
  loading,
  datasetData,
  selectedInstanceIndex,
  customHeaderItem,
  onSelectInstance,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography className="form-item-label">{title}</Typography>
      {loading ? (
        <>
          {new Array(5).fill().map(() => (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={30}
              style={{ marginBottom: ".25rem" }}
            />
          ))}
        </>
      ) : datasetData ? (
        <TableVirtuoso
          style={{ height: "400px" }}
          data={datasetData.data.rows}
          components={VirtualizedTableComponents}
          fixedHeaderContent={() => (
            <StyledTableRow>
              <StyledTableCell
                padding="checkbox"
                key={"empty-checkbox-area"}
              ></StyledTableCell>
              {datasetData.data.header.map((item, index) => (
                <StyledTableCell align="left" key={index}>
                  {customHeaderItem ? customHeaderItem(item, index) : item}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          )}
          itemContent={(index, row) => (
            <>
              <StyledTableCell padding="checkbox" key={index + "checkbox"}>
                <Checkbox
                  color="primary"
                  checked={selectedInstanceIndex === index}
                  onChange={() => onSelectInstance(row, index)}
                />
              </StyledTableCell>
              {row.map((item, dataIndex) => (
                <StyledTableCell align="left" key={dataIndex}>
                  {item}
                </StyledTableCell>
              ))}
            </>
          )}
        />
      ) : (
        <Grid>
          <Alert type="info" message="Could not find dataset." />
        </Grid>
      )}
    </Box>
  );
};

export default InstanceSelector;
