import { TreeType } from "./utils";

function MetricsVisualizer({ metrics, metricsStats, treeType }) {
  return (
    <div id="metrics-container">
      <div className="metric" id="metric-table-header">
        <span className="metric-name">metric</span>
        <span className="metric-value">value</span>
        <span className="metric-average">average</span>
        <span className="metric-max">max</span>
        <span className="metric-min">min</span>
      </div>
      {metrics?.tree &&
        Object.entries(metrics.tree).map(([key, value]) => {
          return (
            typeof value === "number" && (
              <div key={key} className="metric">
                <span className="metric-name">{key}</span>
                <span className="metric-value">
                  {parseFloat(value.toFixed(2))}
                </span>
                <span className="metric-average">
                  {parseFloat(
                    Number(metricsStats.tree.averages[key]).toFixed(2)
                  )}
                </span>
                <span className="metric-max">
                  {parseFloat(Number(metricsStats.tree.maxes[key]).toFixed(2))}
                </span>
                <span className="metric-min">
                  {parseFloat(Number(metricsStats.tree.mins[key]).toFixed(2))}
                </span>
              </div>
            )
          );
        })}
      {metrics?.model &&
        Object.entries(metrics.model).map(([key, value]) => {
          return (
            typeof value === "number" && (
              <div key={key} className="metric">
                <span className="metric-name">
                  {key} {treeType == TreeType.MULTI && "(model)"}
                </span>
                <span className="metric-value">
                  {parseFloat(value.toFixed(2))}
                </span>
                <span className="metric-average">
                  {parseFloat(
                    Number(metricsStats.model.averages[key]).toFixed(2)
                  )}
                </span>
                <span className="metric-max">
                  {parseFloat(Number(metricsStats.model.maxes[key]).toFixed(2))}
                </span>
                <span className="metric-min">
                  {parseFloat(Number(metricsStats.model.mins[key]).toFixed(2))}
                </span>
              </div>
            )
          );
        })}
    </div>
  );
}

export default MetricsVisualizer;
