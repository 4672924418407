import { useQuery, useMutation, useQueryClient } from "react-query";
import ApiClient from "src/axios";
import { GET_REDASH_DASHBOARD_QUERY_KEY } from "src/utils/types";

export const useGetSessions = (projectId) =>
  useQuery(["sessions", projectId], () => {
    return ApiClient.get(`/api/projects/${projectId}/sessions`);
  });

export const useGetSession = (sessionId) =>
  useQuery(["session", sessionId], () => {
    return ApiClient.get(`/api/sessions/${sessionId}`);
  });

export const useGetResults = ({ sessionId, intervalMs = false }) =>
  useQuery(
    ["results", sessionId, intervalMs],
    async () => {
      try {
        const { data } = await ApiClient.get(`/api/sessions/${sessionId}`);
        return data;
      } catch {
        return { status: "", data: null };
      }
    },
    {
      refetchInterval: intervalMs,
    }
  );

export const useGetLogs = ({ sessionId, intervalMs = false }) =>
  useQuery(
    ["logs", sessionId, intervalMs],
    async () => {
      let res;
      try {
        res = await ApiClient.get(`/api/sessions/${sessionId}/logs/data`);
      } catch (error) {
        console.log(error);
        res = "";
      }
      return res;
    },
    {
      refetchInterval: intervalMs,
    }
  );

export const useGetPopulation = ({
  sessionId,
  intervalMs = false,
  enabled = false,
}) =>
  useQuery(
    ["populations", sessionId],
    async () => {
      let res;
      res = await ApiClient.get(`/api/sessions/${sessionId}/results`, {
        params: { type: "population" },
      });

      let individuals = [];
      let uniqueProperties = new Set();
      res.data.forEach((result) => {
        for (let individual of result.results) {
          if (!uniqueProperties.has(individual.model)) {
            uniqueProperties.add(individual.model);
            individuals.push({
              ...individual,
              generation: result.generation,
              sessionId: sessionId,
              resultId: result.id,
            });
          }
        }
      });
      return res;
    },
    {
      refetchInterval: intervalMs,
      enabled: enabled,
    }
  );

export const useGetPopulationsofSession = ({ sessionId, intervalMs = false }) =>
  useQuery(
    ["populations_of_session", sessionId],
    async () => {
      let res;

      res = await ApiClient.get(`/api/sessions/${sessionId}/results`, {
        params: { type: "population" },
      });

      let individuals = [];
      let uniqueProperties = new Set();
      res.data.forEach((result) => {
        for (let individual of result.results) {
          if (!uniqueProperties.has(individual.model)) {
            uniqueProperties.add(individual.model);
            individuals.push({
              ...individual,
              generation: result.generation,
              sessionId: sessionId,
              resultId: result.id,
            });
          }
        }
      });
      return individuals;
    },
    {
      refetchInterval: intervalMs,
      enabled: Boolean(sessionId),
    }
  );

export const useGetMetrics = (sessionId) =>
  useQuery(["metrics", sessionId], () => {
    return ApiClient.get(`/api/sessions/${sessionId}/results`, {
      params: { type: "metrics" },
    }).then((data) => {
      return data.results;
    });
  });

export const useCreateSession = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, dataset, algorithm, configuration, commandType, projectId }) =>
      ApiClient.post(`/api/projects/${projectId}/sessions`, {
        name,
        dataset,
        algorithm,
        configuration,
        commandType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sessions");
      },
    }
  );
};

export const useRunSession = () => {
  return useMutation(({ sessionId }) =>
    ApiClient.post(`/api/sessions/${sessionId}/run`)
  );
};

export const usePauseSession = () => {
  return useMutation(({ sessionId }) =>
    ApiClient.post(`/api/sessions/${sessionId}/stop`)
  );
};

export const useGetSessionBookmarks = (sessionId) =>
  useQuery(
    ["session_bookmarked_with_details", sessionId],
    async () => {
      const res = await ApiClient.get(`/api/sessions/${sessionId}/bookmarks`);
      let bookmarkList = [];

      if (res.data && res.data.length > 0) {
        res.data.forEach((collection) => {
          collection.results.forEach((item) => {
            bookmarkList.push({
              ...item,
              algorithm: collection.algorithm,
              session: collection.session,
              resultId: collection.id,
            });
          });
        });
      }

      res.data = bookmarkList;
      return res;
    },
    {
      enabled: Boolean(sessionId),
    }
  );

export const useGetAlgorithmBookmarks = (projectId, algorithmId) =>
  useQuery(
    ["algorithm_bookmarked_with_details", algorithmId],
    async () => {
      const res = await ApiClient.get(
        `/api/projects/${projectId}/algorithms/${algorithmId}/bookmarks`
      );
      let bookmarkList = [];

      if (res.data && res.data.length > 0) {
        res.data.forEach((collection) => {
          collection.results.forEach((item) => {
            bookmarkList.push({
              ...item,
              algorithm: collection.algorithm,
              session: collection.session,
              resultId: collection.id,
            });
          });
        });
      }

      res.data = bookmarkList;
      return res;
    },
    {
      enabled: Boolean(algorithmId) && Boolean(projectId),
    }
  );

export const useCreateAlgorithmBookmark = () => {
  return useMutation(({ projectId, algorithmId, exprs }) =>
    ApiClient.post(
      `/api/projects/${projectId}/algorithms/${algorithmId}/bookmarks`,
      { exprs }
    )
  );
};

export const useRemoveBookmarkSolution = (successCallback) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ resultId, modelId }) =>
      ApiClient.delete(`/api/bookmarks/${resultId}/models/${modelId}`),
    {
      onSuccess: (_, variables) => {
        const { algorithm, sessionId } = variables;
        if (successCallback) {
          successCallback(variables.modelId);
        }

        if (Boolean(sessionId)) {
          queryClient.invalidateQueries([
            "session_bookmarked_with_details",
            sessionId,
          ]);
        }
        queryClient.invalidateQueries([
          "algorithm_bookmarked_with_details",
          algorithm,
        ]);
      },
    }
  );
};

export const useUpdateSession = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      name,
      dataset,
      headers,
      configuration,
      sessionId,
      expressions,
      customData,
    }) =>
      ApiClient.put(`/api/sessions/${sessionId}`, {
        name,
        dataset,
        headers,
        configuration,
        expressions,
        customData,
      }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["session", variables.sessionId]);
        queryClient.invalidateQueries("sessions");
      },
    }
  );
};

export const useDeleteSession = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ projectId, sessionId }) =>
      ApiClient.delete(`/api/projects/${projectId}/sessions/${sessionId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sessions");
      },
    }
  );
};

// MULTI-TREE
const getSortedGenerations = async (sessionId, sortInfo, paginationInfo) => {
  let body = {};
  let params = {
    type: "generations",
  };
  if (sortInfo) {
    body.sort = sortInfo;
  }
  body.filters = [];
  if (paginationInfo) {
    params.offset = (paginationInfo.page - 1) * paginationInfo.limit;
    params.size = paginationInfo.limit;
  }
  const { data } = await ApiClient.post(
    `/api/sessions/${sessionId}/results`,
    body,
    { params }
  );

  return data;
};
export const useGetSortedMultiTreeGenerations = ({
  sessionId,
  enabled = true,
}) => {
  return useMutation({
    mutationFn: ({ sortInfo, paginationInfo }) =>
      getSortedGenerations(sessionId, sortInfo, paginationInfo),
    enabled: enabled,
  });
};

const getSortedTestGenerations = async (sessionId) => {
  let body = {};
  let params = {
    type: "test_result",
  };
  body.filters = [];
  const { data } = await ApiClient.post(
    `/api/sessions/${sessionId}/results`,
    body,
    { params }
  );

  return data;
};
export const useGetSortedMultiTreeTestResuls = () => {
  return useMutation({
    mutationFn: ({ sessionId }) => getSortedTestGenerations(sessionId),
  });
};

const getSortedPopulations = async (
  sessionId,
  sortInfo,
  paginationInfo,
  filterInfo,
  fieldsInfo,
  prepPlotData
) => {
  let body = {};
  if (sortInfo) {
    body.sort = sortInfo;
  }
  if (filterInfo) {
    body.filters = filterInfo;
  }
  if (fieldsInfo) {
    body.fields = fieldsInfo;
  }
  if (prepPlotData) {
    body.prepPlotData = prepPlotData;
  }

  let params = {
    type: "population",
  };
  if (paginationInfo) {
    params.offset = (paginationInfo.page - 1) * paginationInfo.limit;
    params.size = paginationInfo.limit;
  }
  const { data } = await ApiClient.post(
    `/api/sessions/${sessionId}/results`,
    body,
    { params }
  );

  return data;
};
export const useGetSortedMultiTreePopulations = ({
  sessionId,
  enabled = true,
}) => {
  return useMutation({
    mutationFn: ({ sortInfo, paginationInfo, filterInfo }) =>
      getSortedPopulations(sessionId, sortInfo, paginationInfo, filterInfo),
    enabled: enabled,
  });
};

export const useGetMultiTreeComparisonChartData = ({
  sessionId,
  enabled = true,
}) => {
  return useMutation({
    mutationFn: ({ filterInfo, fieldsInfo, prepPlotData }) =>
      getSortedPopulations(
        sessionId,
        undefined,
        undefined,
        filterInfo,
        fieldsInfo,
        prepPlotData
      ),
    enabled: enabled,
  });
};

const getLastMultiTreeGeneration = async (sessionId) => {
  const { data } = await ApiClient.get(
    `/api/sessions/${sessionId}/results/lastgen`
  );
  return data;
};
export const useGetgetLastMultiTreeGeneration = ({
  sessionId,
  enabled = false,
}) => {
  return useQuery({
    queryKey: [],
    queryFn: ({ sortInfo, paginationInfo, filterInfo }) =>
      getLastMultiTreeGeneration(
        sessionId,
        sortInfo,
        paginationInfo,
        filterInfo
      ),
    enabled: enabled,
  });
};

const getMultiTreeBookmarks = async (sessionId) => {
  const { data } = await ApiClient.get(`/api/sessions/${sessionId}/bookmarks`);
  if (Array.isArray(data) && data.length > 0) {
    return data[0];
  }
  return null;
};
export const useGetMultitreeSessionBookmarks = (sessionId) => {
  return useQuery({
    queryKey: ["multitree_session_bookmarked_with_details", sessionId],
    queryFn: () => getMultiTreeBookmarks(sessionId),
    enabled: Boolean(sessionId),
  });
};

const bookmarkMultiTreeSolution = async (resultId, modelId) => {
  const { data } = await ApiClient.post(
    `/api/results/${resultId}/models/${modelId}/bookmark`
  );
  return data;
};
export const useBookmarkMultitreeSolution = () => {
  return useMutation({
    mutationFn: ({ resultId, modelId }) =>
      bookmarkMultiTreeSolution(resultId, modelId),
  });
};

const removeBookmarkMultiTreeSolution = async (bookmarkModelId, modelId) => {
  const { data } = await ApiClient.delete(
    `/api/bookmarks/${bookmarkModelId}/models/${modelId}`
  );
  return data;
};
export const useRemoveBookmarkMultitreeSolution = () => {
  return useMutation({
    mutationFn: ({ bookmarkModelId, modelId }) =>
      removeBookmarkMultiTreeSolution(bookmarkModelId, modelId),
  });
};

const getRedashLink = async (sessionId) => {
  const { data } = await ApiClient.get(`/api/dashboard/${sessionId}`);
  return data;
};
export const useGetRedashDashboard = (sessionId) => {
  return useQuery({
    queryKey: [GET_REDASH_DASHBOARD_QUERY_KEY, sessionId],
    queryFn: () => getRedashLink(sessionId),
    enabled: Boolean(sessionId),
  });
};
