import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

function MetricsHistogram({ metrics, metricsStats }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
      },
    },
  };

  const labels = Object.entries(metrics)
    .filter(([key, value]) => typeof value === "number")
    .map(([key, value]) => key);

  const [selectedMetrics, setSelectedMetrics] = useState(new Set(labels));

  const data = {
    labels: [...selectedMetrics],
    datasets: [
      {
        label: "expression",
        data: [...selectedMetrics].map((label) => metrics[label]),
        backgroundColor: "#aed2e7",
      },
      {
        label: "average",
        data: [...selectedMetrics].map((label) => metricsStats.averages[label]),
        backgroundColor: "#00e088",
      },
      {
        label: "max",
        data: [...selectedMetrics].map((label) => metricsStats.maxes[label]),
        backgroundColor: "#0101ff",
      },
      {
        label: "min",
        data: [...selectedMetrics].map((label) => metricsStats.mins[label]),
        backgroundColor: "#ad1b00",
      },
    ],
  };

  function toggleMetric(metric) {
    selectedMetrics.has(metric)
      ? setSelectedMetrics(
          (prev) => new Set([...prev].filter((label) => label !== metric)),
        )
      : setSelectedMetrics((prev) => new Set(prev.add(metric)));
  }

  return (
    <div id="metrics-histogram-container">
      <div id="metrics-checkboxes">
        {labels.map((label, index) => (
          <div
            className="checkbox-container"
            key={index}
            onClick={() => toggleMetric(label)}
          >
            <span className="label">{label}</span>
            <span
              className={
                selectedMetrics.has(label) ? "selected checkbox" : "checkbox"
              }
            ></span>
          </div>
        ))}
      </div>
      <Bar options={options} data={data} />
    </div>
  );
}

export default MetricsHistogram;
