import { useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import { useGetProject } from "../hooks/projects";
import { hiddenTabsForPerscriptiveProjects, ProjectTabs } from "./utils";
import { ProjectTypes } from "src/components/project/utils";
import { useMemo } from "react";

const defaultAncestor = { name: "Projects", url: "/projects" };

const ProjectLayout = ({
  children,
  tab,
  isChildFullWidth,
  navAncestors = [],
}) => {
  let params = useParams();
  const { data: project } = useGetProject(params.id);
  const navigate = useNavigate();

  const bredcrumbAncestors = useMemo(() => {
    if (!project) {
      return [defaultAncestor, { name: undefined, url: undefined }];
    }

    return [
      defaultAncestor,
      { name: project.data.name, url: `/project/${params.id}/overview` },
      ...navAncestors,
    ];
  }, [project, navAncestors]);

  return (
    <div id="project">
      {project?.data && (
        <>
          <div className="title-tabs-container">
            <Breadcrumbs ancestors={bredcrumbAncestors} />
            <ul className="tabs-container">
              {ProjectTabs.filter((item) => {
                if (
                  project.data.type == ProjectTypes.PREDICTIVE ||
                  project.data.type == ProjectTypes.ANY
                ) {
                  return true;
                } else {
                  return !hiddenTabsForPerscriptiveProjects.includes(item.url);
                }
              }).map((tabItem) => {
                return (
                  <li
                    key={tabItem.url}
                    className={tab === tabItem.url ? "selected" : ""}
                    onClick={() =>
                      navigate(`/project/${params.id}/${tabItem.url}`)
                    }
                  >
                    {tabItem.title}
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            className={`page-content ${isChildFullWidth ? " full-width-page" : ""}`}
          >
            {project?.data?.type == ProjectTypes.PRESCRIPTIVE &&
            hiddenTabsForPerscriptiveProjects.includes(tab) ? (
              <h4>Not available for prescriptive projects</h4>
            ) : (
              children
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectLayout;
