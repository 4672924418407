import { useRef } from "react";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import MultiTreeEvaluation from "./multi-tree/MultiTreeEvaluation";
import SingleTreeEvaluation from "./single-tree/SingleTreeEvaluation";

function Evaluation() {
  const { session } = useMultiTreeSession();
  const fullscreenRef = useRef(null);
  const isMultiTreeSession = Boolean(session?.algorithm?.multiTree);

  return (
    <div
      className="project-tab"
      id="model-evaluation-section"
      ref={fullscreenRef}
    >
      {isMultiTreeSession ? (
        <MultiTreeEvaluation />
      ) : (
        <SingleTreeEvaluation />
      )}
    </div>
  );
}

export default Evaluation;
