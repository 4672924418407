import { useState, useMemo, useEffect } from "react";
import { SliderButtonDirection } from "src/utils/types";
import { calcMetrics, getSmallestGenAndModelIndex, TreeType } from "../utils";
import ExpressisonVisualizer from "./ExpressisonVisualizer";
import MetricsVisualizer from "../MetricsVisualizer";
import TreeVisualizer from "../TreeVisualizer";
import MosaicPanel from "src/components/common/MosaicPanel";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";

const FIRST_MODEL_INDEX = 1;
const FIRST_TREE_INDEX = 0;
const FIRST_GENERATION_INDEX = 0;

function MultiTreeEvaluation() {
  const { combinedTreeList } = useMultiTreeSession();

  const [parsedExpTree, setParsedExpTree] = useState(null);
  const [parsingError, setParsingError] = useState(null);
  const [generationIndex, setGenerationIndex] = useState(
    FIRST_GENERATION_INDEX
  );
  const [modelIndex, setModelIndex] = useState(FIRST_MODEL_INDEX);
  const [treeIndex, setTreeIndex] = useState(FIRST_TREE_INDEX);
  const [selectedTreeItem, setSelectedTreeItem] = useState(null);
  const [treeItemsByModel, setTreeItemsByModel] = useState([]);

  const [tilesLayout, setTilesLayout] = useState({
    currentNode: {
      direction: "row",
      first: {
        direction: "column",
        first: "expression",
        second: "metrics",
        splitPercentage: 55,
      },
      second: "tree",
      splitPercentage: 40,
    },
    windowsCount: 3,
    closedWindows: ["new", "bar chart"],
  });

  useEffect(() => {
    if (!combinedTreeList) {
      return;
    }

    const { smallestGeneration, smallestModel } =
      getSmallestGenAndModelIndex(combinedTreeList);

    setGenerationIndex(smallestGeneration);
    setModelIndex(smallestModel);
  }, []);

  useEffect(() => {
    const trees = combinedTreeList.filter(
      (item) =>
        String(item.generation) == String(generationIndex) &&
        String(item.individual) == String(modelIndex)
    );
    setTreeItemsByModel(trees);
  }, [modelIndex, generationIndex]);

  useEffect(() => {
    if (!combinedTreeList) {
      return;
    }

    const { smallestGeneration, smallestModel } =
      getSmallestGenAndModelIndex(combinedTreeList);

    const trees = combinedTreeList.filter(
      (item) =>
        String(item.generation) == String(smallestGeneration) &&
        String(item.individual) == String(smallestModel)
    );
    setTreeItemsByModel(trees);
  }, [combinedTreeList]);

  useEffect(() => {
    setSelectedTreeItem(
      treeItemsByModel.length > 0 ? treeItemsByModel[treeIndex] : null
    );
  }, [treeIndex, treeItemsByModel]);

  const metrics = useMemo(() => {
    if (!selectedTreeItem) {
      return null;
    }
    return {
      tree: {
        fitness: selectedTreeItem.fitness,
        size: selectedTreeItem.size,
      },
      model: {
        fitness: selectedTreeItem.modelFitness,
        size: selectedTreeItem.modelSize,
        ...selectedTreeItem.modelOther,
      },
    };
  }, [selectedTreeItem]);

  const metricsStats = useMemo(() => {
    if (!combinedTreeList || !selectedTreeItem) {
      return null;
    }
    const { treeCalculations, modelCalculations } = calcMetrics(
      selectedTreeItem,
      combinedTreeList
    );
    return { tree: treeCalculations, model: modelCalculations };
  }, [combinedTreeList]);

  const findNextAvailableIndex = (activeIndex) => {
    // since there are multiple objects with same generation and individual because of multi tree
    // iterate one by one to find next different model

    if (combinedTreeList.length > activeIndex + 1) {
      const nextIndex = Number(activeIndex) + 1;
      const nextItem = combinedTreeList[nextIndex];
      if (
        nextItem.generation == generationIndex &&
        nextItem.individual == modelIndex
      ) {
        findNextAvailableIndex(nextIndex);
      } else {
        setGenerationIndex(nextItem.generation);
        setModelIndex(nextItem.individual);
        setTreeIndex(FIRST_TREE_INDEX);
      }
    } else {
      const firstItem = combinedTreeList[0];
      setGenerationIndex(firstItem.generation);
      setModelIndex(firstItem.individual);
      setTreeIndex(FIRST_TREE_INDEX);
    }
  };

  const findPrevAvailableIndex = (activeIndex) => {
    // since there are multiple objects with same generation and individual because of multi tree
    // iterate one by one to find prev different model

    if (activeIndex > 0) {
      const prevIndex = Number(activeIndex) - 1;
      const prevItem = combinedTreeList[prevIndex];
      if (
        prevItem.generation == generationIndex &&
        prevItem.individual == modelIndex
      ) {
        findPrevAvailableIndex(prevIndex);
      } else {
        setGenerationIndex(prevItem.generation);
        setModelIndex(prevItem.individual);
        setTreeIndex(FIRST_TREE_INDEX);
      }
    } else {
      const lastItem = combinedTreeList[combinedTreeList.length - 1];
      setGenerationIndex(lastItem.generation);
      setModelIndex(lastItem.individual);
      setTreeIndex(FIRST_TREE_INDEX);
    }
  };

  const changeExpressionIndex = (direction) => {
    if (!combinedTreeList || combinedTreeList.length === 0) return;
    let currentIndex = combinedTreeList.findIndex(
      (item) =>
        item.generation == generationIndex && item.individual == modelIndex
    );

    if (direction == SliderButtonDirection.NEXT) {
      findNextAvailableIndex(currentIndex);
    }
    if (direction == SliderButtonDirection.PREV) {
      findPrevAvailableIndex(currentIndex);
    }
  };

  const handleChangeMosaicLayout = (newCurrentNode) => {
    setTilesLayout({
      ...tilesLayout,
      currentNode: newCurrentNode,
    });
  };

  const ELEMENT_MAP = {
    expression: selectedTreeItem && (
      <ExpressisonVisualizer
        treeItemsByModel={treeItemsByModel}
        individual={selectedTreeItem}
        parsingError={parsingError}
        setParsingError={setParsingError}
        setParsedExpTree={setParsedExpTree}
        onChangeTreeIndex={setTreeIndex}
        changeExpressionIndex={changeExpressionIndex}
      />
    ),
    metrics: metrics && metricsStats && (
      <MetricsVisualizer
        metrics={metrics}
        metricsStats={metricsStats}
        treeType={TreeType.MULTI}
      />
    ),
    tree: parsedExpTree && !parsingError && (
      <TreeVisualizer
        parsedExpTree={parsedExpTree}
        selectedTreeItem={selectedTreeItem}
        treeType={TreeType.MULTI}
      />
    ),
  };

  return (
    <MosaicPanel
      tilesLayout={tilesLayout}
      onChangeLayout={handleChangeMosaicLayout}
      mosaicId="evaluation"
      elementMap={ELEMENT_MAP}
    />
  );
}

export default MultiTreeEvaluation;
