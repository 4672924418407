export const resultsCountsValues = ["3", "5", "10"];
export const DEFAULT_RESULT_COUNTS = "5";
export const DEFAULT_CODICE_MAX_ITER = 100;
export const EXPLANATION_STATUS_CHECK_INTERVAL = 3000;
export const EXPLANATION_STATUS_RETRY_COUNT = 2;

export const ExplanationState = {
  CREATED: "CREATED",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  PENDING: "PENDING",
  UNKNOWN: "UNKOWN",
  RUNNING: "RUNNING",
};

export const CounterfactualAlgorithmType = {
  DICE: "dice",
  CO_DICE: "codice",
};

export const ContinuousDistanceType = {
  Weighted_l1: "weighted_l1",
  Diffusion: "diffusion",
};
export const DEFAULT_CODICE_WEIGHT = 0.5;

export const LossType = {
  Regression: {
    MSE: 'MSE',
    RMSE: 'RMSE',
    MAE: 'MAE',
  },
  Classification: {
    HINGE: 'hinge'
  }
};

export const ModelType = {
  REGRESSION: "regression",
  CLASSIFICATION: "classification",
};

export const ExampleCodiceConfigFileContent = `
{
    "params": {
        "constraints": {
            "features": {
                "ApplicantIncome": {
                    "type": "monotonic",
                    "direction": "increasing"  
                },
                "CoapplicantIncome": {
                    "type": "monotonic",
                    "direction": "decreasing"  
                },
                "Gender": {
                    "type": "immutable"
                },
                "Credit_History": {
                    "type": "dependency",
                    "dependencyType": "causal",
                    "root": "Credit_History",
                    "child": "ApplicantIncome"
                }
            }
        }
    },
    "model": {
        "categorical_features_encoding": "label_encoder",
        "continuous_features_normalization": "standard"
    },
    "cfsearch": {
        "optimizer": "genetic",
        "continuous_distance": {
            "diffusion_params": {
                "k_neighbors": 8,
                "alpha": 1,
                "diffusion_normalization": true
            },
            "weighted_l1_params": {
                "weights": [
                    1,
                    1,
                    1,
                    1
                ]
            }
        },
        "categorical_distance": "hamming",
        "search_normalization": false,
        "search_encoding": false,
        "feature_weights": false
    }
}
`;


export const ExplanationPage = {
  COUNTTERFACTUAL: 'COUNTTERFACTUAL',
  FEATURE_IMPORTANCE: 'FEATURE_IMPORTANCE',
}