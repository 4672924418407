import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  useTheme,
} from "@mui/material";
import { ArrowSquareOut } from "phosphor-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  StyledTableCell,
  StyledTableRow,
} from "src/components/common/TableItems";

const QuickTestResults = ({ results = [] }) => {
  const theme = useTheme();
  const params = useParams();
  console.log("results", results);

  const handleNavigateToSession = (sessionId) => {
    const url = `/project/${params.id}/sessions/multi-tree/${sessionId}`;
    const newTabUrl = `${window.location.origin}/#${url}`;
    window.open(newTabUrl, "_blank");
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "25rem",
        paddingBottom: "7rem",
        overflowY: "auto",
      }}
    >
      {results.map((item, index) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
            padding: "1.5rem .5rem",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            gap={"1rem"}
            alignItems={"center"}
          >
            <Typography variant="body2" fontWeight={600}>
              <b>Model:</b>
            </Typography>
            <Button
              variant="text"
              size="small"
              sx={{
                textTransform: "none",
                fontSize: ".875rem",
                padding: "0.3rem .75rem",
                height: "fit-content",
              }}
              startIcon={<ArrowSquareOut />}
              onClick={() => handleNavigateToSession(item.sessionId)}
            >
              Go to test session
            </Button>
          </Stack>
          <Typography variant="body2">{item.model}</Typography>
          <TableContainer
            component={Paper}
            sx={{ width: "100%" }}
            elevation={0}
          >
            <Table size="small">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: (t) => t.palette.divider,
                      fontWeight: "600 !important",
                    }}
                    align="left"
                  >
                    Fitness:
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: (t) => t.palette.divider,
                    }}
                    align="left"
                  >
                    {item.fitness}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: (t) => t.palette.divider,
                      fontWeight: "600 !important",
                    }}
                    align="left"
                  >
                    Size:
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: (t) => t.palette.divider,
                    }}
                    align="left"
                  >
                    {item.size}
                  </StyledTableCell>
                </StyledTableRow>
                {Object.entries(item.other || {}).map(([key, val]) => (
                  <StyledTableRow>
                    <StyledTableCell
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: (t) => t.palette.divider,
                        fontWeight: "600 !important",
                      }}
                      align="left"
                    >
                      {key}:
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: (t) => t.palette.divider,
                      }}
                      align="left"
                    >
                      {val || "-"}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}
    </Box>
  );
};

export default QuickTestResults;
