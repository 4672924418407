import { StyledTableCell, StyledTableRow } from "../../common/TableItems";
import { Fragment, useMemo, useState } from "react";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import { getCustomTableColor } from "src/utils/Utils";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableSortLabel,
  Stack,
  Typography,
} from "@mui/material";
import { CaretDown, CaretUp } from "phosphor-react";
import {
  DefaultComparisonSortKeys,
  defaultMultiTreeTableHeaders,
  SortDirection,
} from "src/utils/types";
import GenerationTablePagination from "./GenerationTablePagination";

function GenerationsTable({ type }) {
  const {
    session,
    generationResults,
    trainingSort,
    setTrainingSort,
  } = useMultiTreeSession();
  const [expandedRows, setExpandedRows] = useState([]);

  const otherFields = useMemo(() => {
    if (!generationResults || generationResults.length === 0) {
      return [];
    }

    let subTreeOtherFields = new Set();
    let modelOtherFields = new Set();

    generationResults.forEach((item) => {
      Object.keys(item.other || {}).forEach((key) => {
        if (!modelOtherFields.has(key)) {
          modelOtherFields.add(key);
        }
      });

      (item.trees || []).forEach((tree) => {
        Object.keys(tree).forEach((key) => {
          if (
            !subTreeOtherFields.has(key) &&
            !defaultMultiTreeTableHeaders.has(key)
          ) {
            subTreeOtherFields.add(key);
          }
        });
      });
    });
    return {
      model: Array.from(modelOtherFields),
      tree: Array.from(subTreeOtherFields),
    };
  }, [generationResults]);

  const openDetails = (index) => {
    setExpandedRows((prev) => [...prev, index]);
  };

  const closeDetails = (index) => {
    const filtered = expandedRows.filter((i) => i !== index);
    setExpandedRows(filtered);
  };

  const clickOnSort = (field) => {
    if (field === trainingSort.key) {
      setTrainingSort(() => ({
        key: field,
        order:
          trainingSort.order === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC,
      }));
    } else {
      setTrainingSort((prev) => ({ ...prev, key: field }));
    }
  };

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="left"></StyledTableCell>
              <StyledTableCell align="left">
                <TableSortLabel
                  active={
                    trainingSort.key === DefaultComparisonSortKeys.GENERATION
                  }
                  direction={trainingSort.order}
                  onClick={() =>
                    clickOnSort(DefaultComparisonSortKeys.GENERATION)
                  }
                >
                  GEN
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={trainingSort.order}>
                <TableSortLabel
                  active={trainingSort.key === "id"}
                  direction={trainingSort.order}
                  onClick={() => clickOnSort("id")}
                >
                  MODEL
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={trainingSort.order}>
                <TableSortLabel
                  active={trainingSort.key === "size"}
                  direction={trainingSort.order}
                  onClick={() => clickOnSort("size")}
                >
                  SIZE
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={trainingSort.order}>
                <TableSortLabel
                  active={trainingSort.key === "fitness"}
                  direction={trainingSort.order}
                  onClick={() => clickOnSort("fitness")}
                >
                  FITNESS
                </TableSortLabel>
              </StyledTableCell>
              {otherFields.model.map((field) => (
                <StyledTableCell align="left" key={field}>
                  {field}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {generationResults.map((item) => {
              const rowBackgroundColor = getCustomTableColor(Number(item.id));
              const isExpanded = expandedRows.some((i) => i == item.id);
              const isMultiTree = Boolean(session?.algorithm?.multiTree);

              return (
                <Fragment>
                  <StyledTableRow sx={{ backgroundColor: rowBackgroundColor }}>
                    <StyledTableCell align="left">
                      {isMultiTree && (
                        <IconButton
                          sx={{
                            width: "1.5rem",
                            height: "1.5rem",
                            padding: ".25rem",
                          }}
                          onClick={() =>
                            !isExpanded
                              ? openDetails(item.id)
                              : closeDetails(item.id)
                          }
                        >
                          {" "}
                          {isExpanded ? (
                            <CaretUp size={18} />
                          ) : (
                            <CaretDown size={18} />
                          )}
                        </IconButton>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {item.generation || "-"}
                    </StyledTableCell>
                    <StyledTableCell align="left">{item.model}</StyledTableCell>
                    <StyledTableCell align="left">{item.size}</StyledTableCell>
                    <StyledTableCell align="left">
                      {item.fitness}
                    </StyledTableCell>
                    {otherFields.model.map((field) => (
                      <StyledTableCell align="left" key={field}>
                        {String(field) in item.other ? item.other[field] : "-"}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>

                  {/* if subtree */}
                  {isExpanded && (
                    <StyledTableRow
                      sx={{ backgroundColor: rowBackgroundColor }}
                    >
                      <StyledTableCell colSpan={6}>
                        <Fragment sx={{ padding: ".5rem" }}>
                          {(item.trees || []).length === 0 ? (
                            <Typography>No tree exist.</Typography>
                          ) : (
                            <TableContainer
                              component={Paper}
                              sx={{ width: "100%" }}
                            >
                              <Table>
                                <TableHead>
                                  <StyledTableCell align="left">
                                    Id
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    Expression
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    Size
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    Fitness
                                  </StyledTableCell>
                                  {otherFields.tree.map((field) => (
                                    <StyledTableCell align="left" key={field}>
                                      {field}
                                    </StyledTableCell>
                                  ))}
                                </TableHead>
                                <TableBody>
                                  {item.trees.map((tree) => {
                                    return (
                                      <StyledTableRow>
                                        <StyledTableCell align="left">
                                          {tree.id}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {tree.model}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {tree.size}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {tree.fitness}
                                        </StyledTableCell>
                                        {otherFields.tree.map((key) => (
                                          <StyledTableCell align="left">
                                            {tree[key] || "-"}
                                          </StyledTableCell>
                                        ))}
                                      </StyledTableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </Fragment>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <GenerationTablePagination />
    </Stack>
  );
}

export default GenerationsTable;
