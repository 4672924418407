import { useQuery, useMutation, useQueryClient } from "react-query";
import ApiClient from "src/axios";
import { GET_PROJECTS_QUERY_KEY } from "src/utils/types";

const getAllProjects = async () => {
  const {data} = await ApiClient.get("/api/projects");
  return data;
}
export const useGetProjects = () =>
  useQuery({
    queryKey: [GET_PROJECTS_QUERY_KEY],
    queryFn: () => getAllProjects()
  });

export const useGetProject = (projectId) =>
  useQuery(["projects", projectId], () => {
    return ApiClient.get(`/api/projects/${projectId}`);
  });

export const useCreateProject = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, type }) =>
      ApiClient.post("/api/projects", {
        name,
        type,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("projects");
      },
    },
  );
};
