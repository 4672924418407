import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import editorTheme from "react-syntax-highlighter/dist/esm/styles/prism/a11y-dark";
import { PrismLanguage } from "../overview/utils";

const PrettyCode = ({ code, language }) => {
  return (
    <SyntaxHighlighter
      language={language}
      style={editorTheme}
      showLineNumbers={true}
    >
      {language === PrismLanguage.JS
        ? JSON.stringify(code, null, 2)
        : language === PrismLanguage.PYTHON
          ? code
          : ""}
    </SyntaxHighlighter>
  );
};

export default PrettyCode;
