import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/use-auth.js";
import Navbar from "../components/Navbar.js";
import { waiter } from "src/utils/Utils.js";

function RequireAuth({ children }) {
  let auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.loadCurrentUser();
  }, []);

  useEffect(async () => {
    if (!auth.initialCurrentUserLoad || auth.loading) {
      return;
    }

    if (!auth.user) {
      auth.signout();
      await waiter(500);
      navigate("/login");
    } else if (auth.user.tokenExpired) {
      await waiter(500);
      navigate("/login?tokenTimeout=true");
    }
  }, [auth.user, auth.initialCurrentUserLoad, auth.loading]);

  return (
    <div id="require-auth-layout">
      <Navbar />
      {children}
    </div>
  );
}

export default RequireAuth;
