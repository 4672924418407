import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../TableItems";

const SelectedExpressionTable = ({ selectedPopulation, onChange }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: 'column',
        gap: ".5rem",
        marginBottom: ".3rem",
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "600",
        }}
      >
        Selected Expression
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="left" key={"index"}>
                #
              </StyledTableCell>
              <StyledTableCell align="left" key={"expression"}>
                Expression
              </StyledTableCell>
              <StyledTableCell align="left" key={"fitness"}>
                Fitness
              </StyledTableCell>
              <StyledTableCell align="left" key={"size"}>
                Size
              </StyledTableCell>
              <StyledTableCell align="right" key={"action"}></StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell align="left" key={"index"}>
                {selectedPopulation?.index + 1 || "-"}
              </StyledTableCell>
              <StyledTableCell align="left" key={"expression"}>
                {selectedPopulation?.model || '-'}
              </StyledTableCell>
              <StyledTableCell align="left" key={"fitness"}>
                {selectedPopulation?.fitness || '-'}
              </StyledTableCell>
              <StyledTableCell align="left" key={"size"}>
                {selectedPopulation?.size || '-'}
              </StyledTableCell>
              <StyledTableCell align="right" key={"action"}>
                <Button
                  variant="outlined"
                  sx={{ height: "35px" }}
                  onClick={onChange}
                >
                  Change
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SelectedExpressionTable;
