export const ProjectTabs = [
  {
    title: "Overview",
    url: "overview",
  },
  {
    title: "Datasets",
    url: "datasets",
  },
  {
    title: "Configurations",
    url: "configs",
  },
  {
    title: "Sessions",
    url: "sessions",
  },
  {
    title: "Bookmarked",
    url: "bookmarked",
  },
  {
    title: "What-if",
    url: "whatif",
  },
  {
    title: "Counterfactual",
    url: "counterfactual",
  },
  {
    title: "Feature Importance",
    url: "feature-importance",
  },
];


export const hiddenTabsForPerscriptiveProjects = ["whatif","counterfactual","feature-importance"]