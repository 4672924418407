import Iframe from "react-iframe";
import { Alert, AlertTitle, Box, CircularProgress } from "@mui/material";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import { useGetRedashDashboard } from "src/hooks/sessions";

const DASHBOARD_ERR_MESSAGE =
  "Dashboard for the session is not available, make sure the algorithm outputs instances.csv file";

const RedashDashboard = () => {
  const { sessionId } = useMultiTreeSession();

  const {
    data: dashboardData,
    isLoading,
    isError,
  } = useGetRedashDashboard(sessionId);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "4rem",
          }}
        >
          <CircularProgress sx={{ color: (t) => t.palette.primary.main }} />
        </Box>
      ) : isError ? (
        <Alert severity="info" sx={{ margin: "2rem" }}>
          <AlertTitle>Info</AlertTitle>
          {DASHBOARD_ERR_MESSAGE}
        </Alert>
      ) : !dashboardData ? (
        <Alert severity="info" sx={{ margin: "2rem" }}>
          {DASHBOARD_ERR_MESSAGE}
        </Alert>
      ) : (
        <Iframe
          url={`${dashboardData.link}`}
          width="100%"
          height="100%"
          position="relative"
        />
      )}
    </Box>
  );
};

export default RedashDashboard;
