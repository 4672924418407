import React, { Fragment, useMemo, useState } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableSortLabel,
  Typography,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
} from "src/components/common/TableItems";
import { getCustomTableColor } from "src/utils/Utils";
import { BookmarkSimple, CaretDown, CaretUp } from "phosphor-react";
import ComparisonTableModelSubTreeTable from "./ComparisonTableModelSubTree";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import { defaultMultiTreeTableHeaders } from "src/utils/types";

const MultiTreeTable = ({
  bookmarkedItems,
  onChangeSortField,
  onBookmark,
  onRemoveBookmark,
}) => {
  const { filteredSolutions, comparisonSort } = useMultiTreeSession();
  const [expandedRows, setExpandedRows] = useState([]);

  const otherFields = useMemo(() => {
    if (!filteredSolutions || filteredSolutions.length === 0) {
      return [];
    }

    let subTreeOtherFields = new Set();
    let modelOtherFields = new Set();

    filteredSolutions.forEach((item) => {
      Object.keys(item.other || {}).forEach((key) => {
        if (!modelOtherFields.has(key)) {
          modelOtherFields.add(key);
        }
      });

      (item.trees || []).forEach((tree) => {
        Object.keys(tree).forEach((key) => {
          if (
            !subTreeOtherFields.has(key) &&
            !defaultMultiTreeTableHeaders.has(key)
          ) {
            subTreeOtherFields.add(key);
          }
        });
      });
    });
    return {
      model: Array.from(modelOtherFields),
      tree: Array.from(subTreeOtherFields),
    };
  }, [filteredSolutions]);

  const openDetails = (index) => {
    setExpandedRows((prev) => [...prev, index]);
  };

  const closeDetails = (index) => {
    const filtered = expandedRows.filter((i) => i !== index);
    setExpandedRows(filtered);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="left"></StyledTableCell>
            <StyledTableCell align="left" sortDirection={comparisonSort.order}>
              <TableSortLabel
                active={comparisonSort.key === "generation"}
                direction={comparisonSort.order}
                onClick={() => onChangeSortField("generation")}
              >
                #
              </TableSortLabel>
            </StyledTableCell>
            {otherFields.model.map((field) => (
              <StyledTableCell
                align="left"
                sortDirection={comparisonSort.order}
                key={field}
              >
                <TableSortLabel
                  active={comparisonSort.key === `other.${field}`}
                  direction={comparisonSort.order}
                  onClick={() => onChangeSortField(`other.${field}`)}
                >
                  {field}
                </TableSortLabel>
              </StyledTableCell>
            ))}
            <StyledTableCell align="right" />
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {filteredSolutions.map((item) => {
            const isBookmarkedSolution = Boolean(bookmarkedItems.has(item.id));
            const rowBackgroundColor = getCustomTableColor(
              Number(item.individual)
            );
            const isExpanded = expandedRows.some((i) => i == item.id);
            return (
              <Fragment>
                <StyledTableRow key={item.id}>
                  <StyledTableCell align="left">
                    <IconButton
                      sx={{
                        width: "1.5rem",
                        height: "1.5rem",
                        padding: ".25rem",
                      }}
                      onClick={() =>
                        !isExpanded
                          ? openDetails(item.id)
                          : closeDetails(item.id)
                      }
                    >
                      {" "}
                      {isExpanded ? (
                        <CaretUp size={18} />
                      ) : (
                        <CaretDown size={18} />
                      )}
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography variant="body2" sx={{ fontWeight: "600" }}>
                      G{item.generation || "?"}:M{item.individual}
                    </Typography>
                  </StyledTableCell>
                  {otherFields.model.map((field) => (
                    <StyledTableCell align="left">
                      {"other" in item ? item.other[field] || "-" : "-"}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align="right">
                    <IconButton
                      sx={{ width: "32px", height: "32px" }}
                      onClick={() => {
                        isBookmarkedSolution
                          ? onRemoveBookmark(item.id)
                          : onBookmark(item.resultId, item.id);
                      }}
                    >
                      <BookmarkSimple
                        weight="fill"
                        size={17}
                        color={`${isBookmarkedSolution ? "#0101ff" : "#b9c3cb"}`}
                      />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>

                {/* if subtree */}
                {isExpanded && (
                  <ComparisonTableModelSubTreeTable
                    trees={item.trees}
                    otherTreeFields={otherFields.tree}
                    rowBackgroundColor={rowBackgroundColor}
                  />
                )}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MultiTreeTable;
