import { useMemo, useState } from "react";
import { SessionStatus, SessionTabs } from "src/utils/types";
import Comparison from "src/components/multitree/filter/Comparison";
import Training from "src/components/multitree/training/Training";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import Evaluation from "src/components/multitree/evaluation/Evaluation";
import { Tab, Tabs } from "@mui/material";
import ProjectLayout from "src/layouts/ProjectLayout";
import { useParams } from "react-router-dom";
import RedashDashboard from "src/components/multitree/RedashDashborad";

const MultiTreeSession = () => {
  const params = useParams();
  const [selectedTab, setSelectedTab] = useState(SessionTabs.TRAINING);
  const {
    session,
    project,
    sessionStatus,
    filteredSolutions,
    combinedTreeList,
    isTestSession,
    isTrainSession,
  } = useMultiTreeSession();
  const isSingleTreeSession = Boolean(session?.algorithm?.multiTree) == false;

  const navAncestors = useMemo(() => {
    if (!session) {
      return [
        { name: "Sessions", url: `/project/${params.id}/sessions` },
        { name: undefined, url: undefined },
      ];
    }
    return [
      { name: "Sessions", url: `/project/${params.id}/sessions` },
      { name: session?.name, url: undefined },
    ];
  }, [session]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <ProjectLayout
      tab={"sessions"}
      isChildFullWidth={true}
      navAncestors={navAncestors}
    >
      <div id="model-page">
        <div id="session">
          {session && (
            <>
              <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                sx={{ marginBottom: ".5rem" }}
              >
                <Tab
                  value={SessionTabs.TRAINING}
                  label={isTestSession ? "Test Results" : "Training"}
                  sx={{ textTransform: "none" }}
                />
                {isTrainSession && (
                  <Tab
                    value={SessionTabs.FILTERING}
                    label="Filter"
                    sx={{ textTransform: "none" }}
                  />
                )}
                {filteredSolutions && isTrainSession && (
                  <Tab
                    value={SessionTabs.EVALUATION}
                    label="Evaluation"
                    sx={{ textTransform: "none" }}
                  />
                )}
                {isTestSession && isSingleTreeSession && (
                  <Tab
                    value={SessionTabs.DASHBOARD}
                    label="Redash Dashboard"
                    sx={{ textTransform: "none" }}
                  />
                )}
              </Tabs>

              {project && session && (
                <div className="model-page-content">
                  <section
                    className={`mosaic-panel-section ${
                      selectedTab === SessionTabs.TRAINING
                        ? "active"
                        : "passive"
                    }`}
                  >
                    <Training />
                  </section>
                  <section
                    className={`mosaic-panel-section ${
                      selectedTab === SessionTabs.FILTERING
                        ? "active"
                        : "passive"
                    }`}
                  >
                    <Comparison />
                  </section>
                  {combinedTreeList && (
                    <section
                      className={`mosaic-panel-section ${
                        selectedTab === SessionTabs.EVALUATION
                          ? "active"
                          : "passive"
                      }`}
                    >
                      <Evaluation />
                    </section>
                  )}
                  {isSingleTreeSession &&
                    isTestSession &&
                    sessionStatus === SessionStatus.FINISHED && (
                      <section
                        className={`mosaic-panel-section ${
                          selectedTab === SessionTabs.DASHBOARD
                            ? "active"
                            : "passive"
                        }`}
                      >
                        <RedashDashboard />
                      </section>
                    )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </ProjectLayout>
  );
};

export default MultiTreeSession;
