import { Navigate } from "react-router-dom";
import { useAuth } from "src/hooks/use-auth";

const Initial = () => {
  const auth = useAuth();

  if (!auth.user) {
    return <Navigate to="/login" />;
  }

  if (auth.user.tokenExpired) {
    auth.signout();
    return <Navigate to="/login?tokenTimeout=true" />;
  }

  return <Navigate to={"/projects"} />;
};

export default Initial;
