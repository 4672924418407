import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ArrowBendUpLeft,
  ArrowBendUpRight,
  DownloadSimple,
} from "phosphor-react";
import { useMutation } from "react-query";
import PrettyCode from "../common/PrettyCode";
import ApiClient from "src/axios";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import { getBlobTypeErrMsg } from "src/utils/Utils";
import { PrismLanguage } from "./utils";
import { useState } from "react";

const ConfigShowcase = ({ algorithm }) => {
  const { notify } = useNotifier();
  const theme = useTheme();
  const [showFullConfig, setShowFullConfig] = useState(false);

  const { mutate: downloadConfigMutation, isLoading } = useMutation({
    mutationFn: () =>
      ApiClient.get(`/api/algorithms/${algorithm?.id}/download`, {
        responseType: "blob",
        params: {
          type: "algorithm",
        },
      }),
    onSuccess: (res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: "application/json" })
      );
      const a = document.createElement("a");
      a.href = url;
      a.download = `${algorithm.uniqueId}`;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    onError: async (err) => {
      notify(NotificationType.ERROR, await getBlobTypeErrMsg(err));
    },
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box>
          <Typography
            variant="h5"
            sx={{
              fontSize: "1.125rem",
              fontWeight: "600",
              marginBottom: ".25rem",
            }}
          >
            Name
          </Typography>
          <Typography>{algorithm.name}</Typography>
        </Box>
        <Box>
          <Typography
            variant="h5"
            sx={{
              fontSize: "1.125rem",
              fontWeight: "600",
              marginBottom: ".25rem",
            }}
          >
            Description
          </Typography>
          <Typography>{algorithm.description}</Typography>
        </Box>
        <Box>
          <Typography
            variant="h5"
            sx={{
              fontSize: "1.125rem",
              fontWeight: "600",
              marginBottom: ".25rem",
            }}
          >
            Type
          </Typography>
          <Typography>{algorithm.type}</Typography>
        </Box>
      </Box>

      <Button
        onClick={() => {
          setShowFullConfig(!showFullConfig);
        }}
        sx={{
          margin: "1rem 0",
        }}
        startIcon={showFullConfig ? <ArrowBendUpLeft /> : <ArrowBendUpRight />}
      >
        {`${showFullConfig ? "Hide" : "Show"} Detailed Configurations`}
      </Button>

      <Collapse in={showFullConfig}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
            marginBottom: "1rem",
          }}
        >
          <Button
            variant="contained"
            color="success"
            size="small"
            sx={{ height: "34px" }}
            startIcon={
              isLoading ? (
                <CircularProgress
                  size={16}
                  sx={{ color: theme.palette.success.contrastText }}
                />
              ) : (
                <DownloadSimple
                  style={{ color: theme.palette.success.contrastText }}
                />
              )
            }
            onClick={isLoading ? undefined : downloadConfigMutation}
          >
            Download
          </Button>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "auto",
            maxHeight: "27.5rem",
            overflow: "auto",
            borderRadius: ".75rem",
            fontSize: '.875rem'
          }}
        >
          <PrettyCode code={algorithm} language={PrismLanguage.JS} />
        </Box>
      </Collapse>
    </Box>
  );
};

export default ConfigShowcase;
