import { Alert, AlertTitle, Box, Skeleton, Typography } from "@mui/material";

const WhatifResult = ({ title, loading, result, error }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: ".5rem",
        }}
      >
        <Typography sx={{ width: "9.5rem" }}>
          <b>{title}: </b>
        </Typography>
        {loading ? (
          <Skeleton variant="rectangular" animation="wave" width={200} />
        ) : (
          <Typography align="right">{result}</Typography>
        )}
      </Box>
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error.map((item) => (
            <Typography>{item.message}</Typography>
          ))}
        </Alert>
      )}
    </Box>
  );
};

export default WhatifResult;
