import { Box, Grid, TextField, Typography } from "@mui/material";
import { ModelType } from "./utils";
import { Fragment } from "react";

const SelectOutputRange = ({
  title = "Output Range",
  desc,
  value,
  onChange,
  modelType,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: ".5rem",
          marginBottom: ".3rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {title}
        </Typography>
        {desc && <Typography variant="caption">{desc}</Typography>}
      </Box>
      <Grid container spacing={3}>
        {modelType == ModelType.CLASSIFICATION ? (
          <Grid item xs={12}>
          <TextField
            sx={{ width: "100%" }}
            variant="outlined"
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
          </Grid>
        ) : (
          <Fragment>
            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                variant="outlined"
                type="number"
                value={value.start}
                onChange={(e) => onChange({ ...value, start: e.target.value })}
                placeholder="Starting point of output range"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                variant="outlined"
                type="number"
                value={value.finish}
                onChange={(e) => onChange({ ...value, finish: e.target.value })}
                placeholder="Finishing point of output range"
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Box>
  );
};

export default SelectOutputRange;
