import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Trash, Plus } from "phosphor-react";
import dateFormat from "dateformat";
import { Box } from "@mui/system";
import { Alert } from "@mui/material";
import NoDataAlert from "src/components/Alert";
import { useQuery } from "react-query";
import ApiClient from "src/axios";
import {
  GET_SESSIONS_QUERY_KEY,
  GENERAL_ERROR_MESSAGE,
  SESSION_REQ_INTERVAL,
} from "src/utils/types";
import CreateSessionModal from "src/components/session/CreateSessionModal";
import Status from "src/components/common/Status";
import DeleteSessionDialog from "src/components/session/DeleteSessionDialog";
import ProjectLayout from "src/layouts/ProjectLayout";

function Sessions() {
  let navigate = useNavigate();
  let params = useParams();

  const [allSessions, setAllSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [search, setSearch] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [deleteSessionDialogOpen, setDeleteSessionDialogOpen] = useState(false);

  useQuery({
    queryKey: [GET_SESSIONS_QUERY_KEY],
    queryFn: () => ApiClient.get(`/api/projects/${params.id}/sessions`),
    onSuccess: (res) => {
      setAllSessions(res.data);
      setLoading(false);
      setError("");
    },
    onError: () => {
      setError(GENERAL_ERROR_MESSAGE);
      setLoading(false);
    },
    refetchInterval: SESSION_REQ_INTERVAL,
  });

  const deleteSession = (session) => {
    setSelected(session);
    setDeleteSessionDialogOpen(true);
  };

  const filteredSessions = useMemo(() => {
    return allSessions.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [allSessions, search]);

  const handleNavigateToSession = (session) => {
    const url = `/project/${params.id}/sessions/multi-tree/${session.id}`;
    navigate(url);
  };

  return (
    <ProjectLayout tab={"sessions"}>
      <div className="project-tab">
        <div id="sessions-top">
          <form id="session-search-form">
            {!error && (
              <input
                type="text"
                id="session-search"
                placeholder="Search a session"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              ></input>
            )}
          </form>
          <button
            id="session-create-button"
            className="icon-button"
            type="button"
            onClick={() => setShowCreateModal(true)}
          >
            <Plus size={18} color="#ffffff" weight="bold" />
            <span>New Session</span>
          </button>
        </div>
        {allSessions.length === 0 && !loading && (
          <Box sx={{ width: "100%", mt: "2rem" }}>
            <NoDataAlert type="info" message="No session found." />
          </Box>
        )}
        <div id="sessions-table">
          {error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            allSessions.length > 0 && (
              <>
                <div className="table-top">
                  <span className="name">NAME</span>
                  <span className="command-type">TYPE</span>
                  <span className="status-container">STATUS</span>
                  <span className="algorithm">ALGORITHM</span>
                  <span className="last-modified">LAST MODIFIED</span>
                  <span className="trash"></span>
                </div>
                <div className="table-body">
                  {filteredSessions &&
                    filteredSessions.map((session, index) => {
                      return (
                        <li key={index} className="table-entry">
                          <span
                            className="name"
                            onClick={() => handleNavigateToSession(session)}
                          >
                            {session.name}
                          </span>
                          <span className="command-type">
                            {session.properties
                              ? session.properties.commandType
                              : "-"}
                          </span>
                          <span className="status-container">
                            <Status status={session.status} />
                          </span>
                          <span className="algorithm">
                            {session.algorithm.name}
                          </span>
                          <span className="last-modified">
                            {dateFormat(
                              session.updated,
                              "dd/mm/yyyy, hh:MM TT"
                            )}
                          </span>
                          <span
                            className="trash"
                            onClick={() => deleteSession(session)}
                          >
                            <Trash size={20} color="#ad1b00" />
                          </span>
                        </li>
                      );
                    })}
                </div>
              </>
            )
          )}
          {loading && <div className="table-loader"></div>}
        </div>
        {showCreateModal && (
          <CreateSessionModal
            open={showCreateModal}
            onClose={() => setShowCreateModal(false)}
          />
        )}
        {deleteSessionDialogOpen && selected && (
          <DeleteSessionDialog
            open={true}
            sessionId={selected.id}
            sessionName={selected.name}
            onClose={() => setDeleteSessionDialogOpen(false)}
          />
        )}
      </div>
    </ProjectLayout>
  );
}

export default Sessions;
