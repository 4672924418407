import { useState, useEffect, Fragment } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress,
  Button,
  useTheme,
  Box,
} from "@mui/material";
import { useMutation } from "react-query";
import ApiClient from "src/axios";
import WhatifResult from "./WhatifResult";
import ChangeExpressionDialog from "../common/expression-table/ChangeExpressionDialog";
import SelectedExpressionTable from "../common/expression-table/SelectedExpressionTable";
import ChangeDataInstance from "./ChangeDataInstance";

const WhatifAnalyseDialog = ({
  open,
  expression: initialExpression,
  instance: initialInstance,
  sessionId,
  dataHeaders,
  instance,
  targetLabel,
  allPopulations,
  onClose,
}) => {
  const theme = useTheme();
  const [selectedPopulation, setSelectedPopulation] = useState(null);
  const [changedInstance, setChangedInstance] = useState([]);
  const [initialAnalyse, setInitialAnalyse] = useState({
    res: null,
    loading: true,
    error: null,
  });
  const [customAnalyse, setCustomAnalyse] = useState({
    res: null,
    loading: false,
    error: null,
  });
  const [changeExpressionOpen, setChangeExpressionOpen] = useState(false);

  const { mutateAsync: analyseMutation } = useMutation({
    mutationFn: ({ instance, expression }) =>
      ApiClient.post(`/api/${sessionId}/whatif`, {
        instance: instance,
        expr: expression,
      }),
  });

  useEffect(() => {
    let initialPopulation;
    (allPopulations || []).forEach((item, index) => {
      if (item.model === initialExpression) {
        initialPopulation = {
          ...item,
          index: index,
        };
        return;
      }
    });
    setSelectedPopulation(initialPopulation);
    setChangedInstance(initialInstance);

    analyseMutation({
      instance: initialInstance,
      expression: initialExpression,
    })
      .then((res) => {
        setInitialAnalyse((prev) => ({
          ...prev,
          res: res.data
            ? res.data.output
              ? res.data.output
              : "Could not found any result."
            : "Failed to evaluate expression.",
          loading: false,
        }));
      })
      .catch((err) => {
        setInitialAnalyse((prev) => ({
          ...prev,
          error: err?.response?.data?.errors || [
            {
              message:
                "Error occured while working with initial instance. Please try later!",
            },
          ],
          loading: false,
        }));
      });
  }, []);

  const handleChangeInstance = (ev, index) => {
    const copyInstance = [...changedInstance];
    copyInstance[index] = ev.target.value;
    setChangedInstance(copyInstance);
  };

  const handleWhatifAnalyse = () => {
    setCustomAnalyse((prev) => ({ ...prev, error: null, loading: true }));
    analyseMutation({
      instance: changedInstance,
      expression: selectedPopulation?.model,
    })
      .then((res) => {
        setCustomAnalyse((prev) => ({
          ...prev,
          res: res.data
            ? res.data.output
              ? res.data.output
              : "Could not found any result."
            : "Failed to evaluate expression.",
          loading: false,
        }));
      })
      .catch((err) => {
        setCustomAnalyse((prev) => ({
          ...prev,
          error: err?.response?.data?.errors || [
            { message: "Error occured. Please try later!" },
          ],
          loading: false,
        }));
      });
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }} variant="h6" fontWeight={600}>
          What-if Analyse
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} mt={2}>
              <SelectedExpressionTable
                selectedPopulation={selectedPopulation}
                onChange={() => setChangeExpressionOpen(true)}
              />
            </Grid>
            <Grid item xs={12}>
              <ChangeDataInstance
                instance={instance}
                targetLabel={targetLabel}
                changedInstance={changedInstance}
                dataHeaders={dataHeaders}
                onChangeInstance={handleChangeInstance}
              />
            </Grid>
            <Grid item xs={12} mt={4}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: ".75rem",
                }}
              >
                <WhatifResult
                  title={"Actual Data Result"}
                  loading={initialAnalyse.loading}
                  result={(initialAnalyse.res || "").toString()}
                  error={initialAnalyse.error}
                />
                <WhatifResult
                  title={"Analyse Data Result"}
                  loading={customAnalyse.loading}
                  result={(customAnalyse.res || "").toString()}
                  error={customAnalyse.error}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color={"error"}
            onClick={onClose}
            sx={{ marginRight: ".25rem" }}
          >
            Close
          </Button>
          <Button
            disabled={!selectedPopulation}
            variant="contained"
            startIcon={
              customAnalyse.loading ? (
                <CircularProgress
                  size={16}
                  sx={{ color: theme.palette.primary.contrastText }}
                />
              ) : undefined
            }
            onClick={
              customAnalyse.loading ? undefined : () => handleWhatifAnalyse()
            }
          >
            Evaluate
          </Button>
        </DialogActions>
      </Dialog>

      {changeExpressionOpen && (
        <ChangeExpressionDialog
          allPopulations={allPopulations || []}
          onSelect={setSelectedPopulation}
          onClose={() => setChangeExpressionOpen(false)}
        />
      )}
    </Fragment>
  );
};

export default WhatifAnalyseDialog;
