import { Fragment } from "react";
import { Typography } from "@mui/material";
import Dropdown from "src/components/Dropdown";

const SessionSelector = ({
  sessions = [],
  selectedSession,
  loading,
  onChangeSession,
}) => {
  return (
    <Fragment>
      <Typography className="form-item-label">Select session</Typography>
      <Dropdown
        value={selectedSession}
        onChange={onChangeSession}
        isDisabled={loading}
        options={sessions.map((item) => {
          return {
            value: item.id,
            label: item.name,
            dataset: item.dataset,
            isMultiTree: Boolean(item.algorithm?.multiTree)
          };
        })}
      />
    </Fragment>
  );
};

export default SessionSelector;
