import { Fragment } from "react";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { ModelType, resultsCountsValues } from "./utils";
import SelectOutputRange from "./SelectOutputRange";

const DiceFormSection = ({
  outputRange,
  resultsCount,
  onChangeResultCount,
  onChangeOutputRange,
}) => {
  return (
    <Fragment>
      <Grid item xs={12}>
        <SelectOutputRange
          title="Output Range"
          desc={
            "(suggested min and max value are %10 below and above the data)"
          }
          modelType={ModelType.REGRESSION}
          value={outputRange}
          onChange={onChangeOutputRange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            mb: ".3rem",
          }}
        >
          Results Count
        </Typography>
        <Select
          id="resultcount-select"
          fullWidth
          value={resultsCount}
          onChange={(e) => onChangeResultCount(e.target.value)}
        >
          {resultsCountsValues.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Fragment>
  );
};

export default DiceFormSection;
