export const ProjectTypes = {
  PREDICTIVE: 'predictive',
  PRESCRIPTIVE: 'prescriptive',
  ANY: 'any',
}

export const ProjectTypeList = [
  { id: ProjectTypes.PREDICTIVE, label: "Predictive" },
  { id: ProjectTypes.PRESCRIPTIVE, label: "Prescriptive" },
  { id: ProjectTypes.ANY, label: "Any" },
];
