import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import { MULTI_TREE_FIRST_PAGE, PaginationLimits } from "src/utils/types";

const GenerationTablePagination = (props) => {
  const { generationResults, trainingPagination, setTrainingPagination } =
    useMultiTreeSession();

  const handleChange = (_, value) => {
    setTrainingPagination((prev) => ({
      ...prev,
      page: value,
    }));
  };

  const changeLimit = (e) => {
    setTrainingPagination(() => ({
      page: MULTI_TREE_FIRST_PAGE,
      limit: e.target.value,
    }));
  };

  const totalPageSize = Math.ceil(
    Number(trainingPagination.itemCount) / Number(trainingPagination.limit)
  );

  return (
    <Box
      sx={{
        marginTop: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        flexWrap: "wrap",
        padding: ".5rem",
      }}
      {...props}
    >
      <Pagination
        count={totalPageSize}
        color="primary"
        disabled={!generationResults}
        page={trainingPagination.page}
        onChange={handleChange}
      />
      <FormControl sx={{ width: "5rem" }} variant="outlined" size="small">
        <InputLabel id="explainer">Limit</InputLabel>
        <Select
          fullWidth
          id="explainer"
          name="explainer"
          label="Limit"
          disabled={!generationResults}
          value={trainingPagination.limit}
          onChange={changeLimit}
        >
          {Object.entries(PaginationLimits).map(([_, val]) => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default GenerationTablePagination;
